//@import '_variables.scss';

/* HUBSPOT FORM STYLING */
.hbspt-form {

  p {
    color: $black;
  }

  ul.hs-error-msgs {
    padding-left: 0;
  }
  
  .inputs-list{
    padding-left: 1rem;
  }
  li {
    list-style-type: none;
  }

  .hs-input{
      display: inline-block;
  }

  input, select, textarea {
    font-family: $font-family-sans-serif;
    outline: none;
    box-sizing:border-box;
    color: $black;
    border-radius: .25rem;
    border: 1px solid #ced4da;
    font-weight: normal;
    font-size: 1.35rem;
    width: 100% !important;
    margin-bottom: 12px;
    position: relative;
    display: block;
  }

  textarea {
    min-height: 80px;
  }

  .form-columns-2, .form-columns-1 {
      max-width: 100% !important;
  }

  input[type="submit"] {
    background-color: $primary !important;
    border: none !important;
    font-family: $font-family-sans-serif;
    -webkit-appearance: none;
    cursor: pointer;
    text-transform: uppercase;
    display: block;
    color: $white;

    &:hover {
      background-color: $primary !important;
      border: 0px solid #333 !important;
    }
  }

  input[type="checkbox"] {
    width: auto !important;
    margin: 0;
    margin-right: 10px;
  }

}


::placeholder {
    color: $white;
    opacity: 1;
}

:-ms-input-placeholder {
    color: $white;
}

::-ms-input-placeholder {
    color: $white;
}


@media (max-width: 480px) {
  .hbspt-form input,
  .hbspt-form select,
  .hbspt-form textarea {
    padding: 6px;
    border-radius: 0 !important;
    -webkit-appearance: none;
  }
  .hbspt-form input[type="submit"] {
    padding: 8px !important;
    font-size: 12px;
    border-radius: 0 !important;
    -webkit-appearance: none;
  }
  .hbspt-form select {
    background: none;
    border-radius: 0 !important;
    -webkit-appearance: none;
  }

}


.newsletter-subscription {
    .hs-input {
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: $black;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .hs-input::placeholder  {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $black;
        font: normal normal normal 16px/22px Nunito;
        line-height: 1.5em;
        opacity:0.75;
    }

    .hs-error-msgs label {
        display: block;
        color: $black;
        font-family: $font-family-sans-serif;
    }  
}
