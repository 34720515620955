
// Custom Css Here
@use "sass:math";

html, body {
    scroll-behavior: smooth;
}
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
}

// Temp. fix for bugs start
//
.d-lg-none.row.flex-nowrap.justify-content-between.pt-3:has([data-toggle="offcanvas"]) {
    display: none;
    content-visibility: hidden;
}
.col:has(> .stretched-link) {
    position: relative;
}
//
// Temp. fix for bugs end

a {
    font-weight: 700;

    @include hover-focus {
        color: lighten($link-color, 15%);
    }
}

.text-secondary-alt {
    color: $secondary-alt !important;

    @include hover-focus {
        color: $secondary-alt !important;
    }
}

@media (max-width: 991.98px) {
    body {
        transition: visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out, visibility .3s ease-in-out;
        transition: transform .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
    }

    body.nav-open {
        -webkit-transform: translateX(70%);
        transform: translateX(70%);
    }
}

#primaryCarouselMobile, #primaryCarousel {
    .carousel-item {
        height: 100vh;

        img {
            height: 100%;
            object-fit: cover;
        }
    }
}

#primaryCarouselMobile {
    .carousel-item {
        img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            object-position: center center;

            @include media-breakpoint-down(sm) {
                object-position: 55% center;
            }

            @include media-breakpoint-down(xs) {
                object-position: 63% center;
            }
        }

        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: .35;
            top:0;
            left:0;
            right:0;
            bottom:0;
        }
    }
}

#primaryCarousel {
    .carousel-item {
        height: calc(100vh - 120px);  // subtract nav menus
    }
}

.carousel-indicators > li {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

/* Global Styles */

// root variables
:root {
    // Additional border radius utitlity
    --#{$prefix}border-radius-2xl: #{$border-radius-2xl};
}

.form-group {
    margin-bottom: 1rem;
}

// Styling inline SVG icons
.svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.125em;
    fill: currentColor;
}

// add transition to links
@each $color, $value in $theme-colors {
    .link-#{$color} {
        transition: all 0.15s ease-in-out;
    }
}

// primary button
.btn-primary {
    --bs-btn-color: #{$white};
    --bs-btn-hover-color: #{$primary};
    --bs-btn-hover-bg: #{$lt-gray};
    --bs-btn-hover-border-color: #{$primary};
    --bs-btn-active-color: #{$primary};
    --bs-btn-active-bg: #{$lt-gray};
    --bs-btn-active-border-color: #{$primary};
    --bs-btn-border-width: 2px;

    &:focus {
        color: #{$primary} !important;
        background-color: #{$lt-gray};
    }

    &.text-white {
        color: #{$white} !important;

        &:hover {
            color: #{$primary} !important;
        }
    }
}

// secondary button
.btn-secondary {
    --bs-btn-color: #{$primary};
    --bs-btn-hover-color: #{$primary};
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #{$secondary};
    --bs-btn-active-color: #{$primary};
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #{$secondary};
    --bs-btn-border-width: 2px;
}

// secondary button
.btn-light-red {
    --bs-btn-color: #{$primary};
    --bs-btn-border-color: #{$primary};
    --bs-btn-hover-color: #{$primary};
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #{$primary};
    --bs-btn-active-color: #{$primary};
    --bs-btn-active-bg: #{$light-red};
    --bs-btn-active-border-color: #{$primary};
    --bs-btn-border-width: 2px;
}

p {
    text-wrap: pretty;
}
    
    p + hr {
        margin-top: 30px;
    }

    hr.NoMargin {
        margin: 0;
    }

    hr.NoMarginTop {
        margin-top: 0;
    }

    hr.NoMarginBottom {
        margin-bottom: 0;
    }

    .fa-20px {
        font-size: 20px;
    }

    .p-italic {
        font-size: 13px;
        font-style: italic;
        color: #6b6b6b;
    }

    .font-small {
        font-size: 12px;
    }

    .text-decoration-underline {
        text-decoration: underline;
    }

    .list-inline-item:not(:last-child) {
        margin-right: 3rem;
    }

    .tooltip-inner {
        text-align: left;
    }

    .form-note {
        font-size: 14px;
        line-height: 15px;
        text-transform: none;
    }

    .black {
        color: #000;
    }

    .green {
        color: #13a52b !important;
    }

    .gray {
        color: #bbb;
    }

    .bg-red {
        background-color: #d9534f !important;
    }

    .bg-blue {
        background-color: #428bca !important;
    }

    .redhighlight {
        color: $danger;

        @include hover-focus {
            color: darken($danger, 15%);
        }
    }

    .img-border {
        border: 1px solid #ccc;
    }

    .img-border-2 {
        border: 2px solid #e5e5e5;
    }

    .border-light-gray {
        border-color: #ddd;
    }

    .padding-2 {
        padding: 2px;
    }

    .no-margin {
        margin: 0px;
    }

    .p-2rem {
        padding: 2rem;
    }

    .cccard {
        &.disabled {
            label {
                color: #bbb;
            }
        }
    }

.font-italic {
    font-style: italic !important;
}

a:has(> :is(h1,h2,h3,h4,h5,h6)),
:is(h1, h2, h3, h4, h5, h6):has(> a) {
    text-decoration: none;
}

.d-column-2 {
    a:is(.text-body) {
        @extend .link-body;
        @extend .link-offset-2;
        @extend .link-underline-opacity-0;
        @extend .link-underline-opacity-100-hover;
    }

    a:is(.link-body) {
        @extend .link-offset-2;
        @extend .link-underline-opacity-0;
        @extend .link-underline-opacity-100-hover;
    }
}

    /* Tables */
    .table {

        &.th-nowrap {
            th {
                white-space: nowrap;
            }
        }

        &.dark-links tr td a {
            color: $secondary;

            @include hover-focus {
                color: darken($primary, 15%);
            }
        }

        &.table-bordered th, &.table-bordered td {
            border: 1px solid #fff;
        }

        &.table-bordered-outline {
            border: 1px solid #464646;
        }

        &.table-bordered-outline th, &.table-bordered-outline td {
            border: none;
        }

        th, td {
            padding: 0.4286rem 0.7143rem 0.4286rem 1.1rem;
        }

        &.dataTable {

            &.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before, .dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
                top: 9px;
                height: 12px;
                width: 12px;
                line-height: 12px;
                background-color: $primary;
            }
        }

        &.order-table {
            margin-top: 20px;

            tr {
                th {
                    text-align: center;
                    border-bottom: 1px solid #111;
                    padding: 10px 10px;

                    &:first-of-type {
                        text-align: left;
                    }
                }

                td {
                    text-align: center;

                    &:first-of-type {
                        text-align: left;
                    }
                }
            }

            thead .sorting:before, thead .sorting:after, thead .sorting_asc:before, thead .sorting_asc:after, thead .sorting_desc:before, thead .sorting_desc:after, thead .sorting_asc_disabled:before, thead .sorting_asc_disabled:after, thead .sorting_desc_disabled:before, thead .sorting_desc_disabled:after {
                bottom: 0.70em;
                font-size: 16px;
            }
        }
    }

    .dataTables_wrapper {
        .dataTables_paginate {
            ul.pagination {
                @include media-breakpoint-down(xs) {
                    margin-top: 10px !important;
                    justify-content: center !important;

                    li.paginate_button.previous {
                        display: inline;
                    }

                    li.paginate_button.next {
                        display: inline;
                    }

                    li.paginate_button {
                        display: none;
                    }
                }
            }
        }

        .custom-select {
            background-color: #ebebeb !important;
        }
    }

    .table thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
        border-top: none;
        color: #464646;
    }

    .table-light {
        thead th {
            color: #fff;
            background-color: #aeaeae;
            border-bottom: 0px;
            font-weight: normal;
        }

        .table-bordered th, .table-bordered td {
            border: 1px solid #fff;
        }

        tbody th {
            background-color: #e7e7e7;
        }

        tbody td {
            background-color: #f0f0f0;
        }

        th, td {
            padding: 0.4286rem 0.7143rem 0.4286rem 1.1rem;
        }
    }

    .table-large {
        thead th {
            background-color: #fff;
            border-bottom: 0px;
            font-weight: normal;
            font-size: 18px;
        }

        .table-bordered th, .table-bordered td {
            border: 1px solid #fff;
        }

        tbody th {
            background-color: #e7e7e7;
        }

        tbody td {
            background-color: #f0f0f0;
        }

        th, td {
            padding: 20px;
        }
    }
    // For new order view
    .table-responsive > .table-details.table-bordered th, .table-responsive > .table-details.table-bordered td {
        border: 1px solid #fff !important;
    }

    .table-responsive > .table-details.table-bordered-2 th, .table-responsive > .table-details.table-bordered-2 td {
        border: 2px solid #fff !important;
    }

    .table-details {
        margin-bottom: 0px;

        thead th {
            background-color: #fff;
            border-bottom: 0px;
            font-weight: bold;
            font-size: 14px;
        }

        .table-bordered th, .table-bordered td {
            border: 1px solid #fff !important;
        }

        tbody th {
            background-color: #e7e7e7;
        }

        tbody td {
            background-color: #f0f0f0;
        }

        th, td {
            padding: 8px;
            font-size: 14px;
        }
    }
    // End: For new order view
    // For old order view
    .table-responsive > .table-details-old.table-bordered th, .table-responsive > .table-details-old.table-bordered td {
        border: 2px solid #fff !important;
    }

    .table-details-old {
        margin-bottom: 0px;

        thead th {
            background-color: #fff;
            border-bottom: 0px;
            font-weight: bold;
            font-size: 16px;
        }

        .table-bordered th, .table-bordered td {
            border: 2px solid #fff !important;
        }

        tbody th {
            background-color: #e7e7e7;
        }

        tbody td {
            background-color: #f0f0f0;
        }

        th, td {
            padding: 8px;
            font-size: 16px;
        }
    }
    // End: For old order view

    .not-active {
        pointer-events: none;
        cursor: default;
        color: grey;
    }
    /* Video Embeds */
    .embed-responsive {
        &.embed-border {
            border: 1px solid #e5e5e5;
        }
    }
    /* Customer Service */
    .customer-service {
        .header {
            padding-bottom: 50px;

            @include media-breakpoint-down(sm) {
                padding-bottom: 40px;
            }

            .phone {
                color: $primary;
                font-size: 36px;
                line-height: 0.78;
            }

            .quote {
                font-size: 44px;
                color: #ff0000;
                line-height: 1.3;
                font-weight: 300;

                @include media-breakpoint-down(md) {
                    font-size: 33px;
                }

                @include media-breakpoint-down(sm) {
                    text-align: center;
                    font-size: 26px;
                }
            }

            .contact {
                width: 280px;
                margin: 0 25px 0 15px;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin: 0 auto 30px auto;
                }

                .chat-button {
                    margin-bottom: 13px;

                    @include media-breakpoint-down(sm) {
                        font-size: 20px;
                    }
                }
            }
        }

        .reviews {
            .eKomi {
                width: 235px;
                height: 134px;
                margin: auto;
                overflow: hidden;
            }

            .BBB {
                width: 150px;
                height: 68px;
                margin: auto;
                overflow: hidden;
            }

            .faceBook {
                width: 170px;
                margin: auto;
                overflow: hidden;
            }
        }
    }

    .hot-off-the-press {

        .left-side {
            width: 350px;
            margin: 0;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    .ultimate-guide {
        position: relative;
        background-color: #aeaeae;

        @include media-breakpoint-down(md) {
            padding: 40px 0 0 0;
        }

        .bg-image {
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url(/images/lg-bk-icon.png);
            background-repeat: no-repeat;
            background-position: center 10px;
            opacity: .15;
            z-index: 1;
        }

        .content {
            position: relative;
            margin: 0 5rem;
            z-index: 10;

            @include media-breakpoint-down(md) {
                text-align: center;
                margin: 0 0;
            }

            div {
                font-weight: 700;
                color: #4e4e4e;
                text-transform: uppercase;
            }

            h1 {
                color: #ffffff;
                line-height: 1.1;
            }

            p {
                font-size: 1.25rem;
                color: #4e4e4e;
            }
        }

        .image {
            position: relative;
            margin: 0 2rem;
            z-index: 10;

            @include media-breakpoint-down(md) {
                text-align: center;
                margin: 0 0 0 0;
            }

            img {
                max-height: 300px;
            }
        }
    }

    .quick-links-container {
        .card-columns {
            display: block;
            column-gap: 10px;
            column-count: 4;
            orphans: 1 !important;
            widows: 1 !important;

            @include media-breakpoint-down(md) {
                column-count: 4;
            }

            @include media-breakpoint-down(sm) {
                column-count: 2;
            }

            .card {
                position: relative;
                border-radius: 0;
                border: 0px;

                @include hover-focus {
                    .card-text {
                        color: darken($primary, 15%);
                    }
                }


                a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                .card-image {
                    background-color: #aeaeae;
                    text-align: center;
                    margin-bottom: 5px;
                }

                .card-text {
                    color: #ff0000;
                    font-size: 14px;
                    font-weight: bold;
                    padding: 0 0 0 0;
                    transition: .5s;
                }
            }
        }
    }

    .print-my-book {
        .copy {
            padding: 0 10px;
            margin: 10px 0 0 0;
        }
    }
/* Forms */
.basic-form {
    h2 {
        margin-bottom: 20px;
    }

    .col-form-label {
        text-transform: none;

        &.label-right {
            margin-left: 10px;
            margin-right: 0px;
        }

        &.label-left {
            margin-right: 20px;
            margin-left: 0px;
        }

        &.label-normal {
            font-size: 16px;
            text-transform: none;
        }

        &.label-alt {
            color: $primary;
            font-weight: bold;
        }
    }

    .form-control-normal {
        font-size: 14px;
        background-color: #fff;
        border-radius: 0;
        border: 2px solid #aeaeae;
    }

    .form-btn-group {
        margin-top: 50px;
    }

    .form-check {
        .form-check-label {
            padding-top: 2px;
            padding-bottom: 2px;
        }
    }

    .form-check-input {
        transform: scale(1.3);
        margin-top: 0;

        &.left-check {
            margin-left: 3px;
        }
    }

    .form-check-inline {
        display: inline-flex;
        align-items: center;
        padding-left: 0;
        margin-right: 0.75rem;
    }

    hr {

        &.hr-form-group {
            @include media-breakpoint-down(sm) {
                margin: 30px 0 14px 0;
            }
        }

        @include media-breakpoint-down(sm) {
            margin: 15px 0 15px 0;
        }
    }

    input.postal-code {
        width: 80px;
        background: #ddd;
        color: #4e4e4e;
        border: 2px solid #aeaeae;
        border-radius: 0;
        font-weight: 600;
        text-align: center;
    }
}

.opt-in {
    border: #aeaeae solid 2px;
    padding: 25px 10px;

    .social {
        text-align: right;

        img {
            width: 37px;
            margin: 0 5px;
            vertical-align: middle;
            margin-bottom: 15px;
        }
    }
}

div.form {
    .form-group-radio {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 40px;
        height: 23px;
        line-height: 23px;
        font-size: 16px;

        &:last-of-type {
            margin-right: 10px;
        }

        span {
            display: inline-block;
            height: 23px;
            width: 23px;
            background: url(/images/order/form/radio_bg.png) no-repeat left;
            vertical-align: middle;
            margin-left: 5px;
            cursor: pointer;
        }

        &.checked {
            span {
                background-image: url(/images/order/form/radio_bg_checked.png);
            }
        }
    }
}

/* Account Nav Start */
.account-nav {
    .nav-item {

        @include media-breakpoint-down(sm) {
            display: block;
            width: 100%;
        }

        .nav-link {
            padding: 0rem 2.6rem 0rem 0rem;
            color: #4d4d4d;
            font-size: 22px;
            text-transform: uppercase;
            font-weight: bold;
            transition: .5s;

            &.active {
                color: $primary;
            }

            &.disabled {
                color: #8f8f8f;
            }

            @include hover-focus {
                color: $primary;
            }
        }
    }
}

/* Alert */
.alert {
    // alert variables start
    --#{$prefix}alert-border: 0 solid var(--#{$prefix}alert-border-color);
    --#{$prefix}alert-border-radius: 0;
    --#{$prefix}alert-margin-bottom: 2rem;
    // alert variables end
    padding-left: calc(64px + 1rem);

    /* info icon */
    &.alert-info {
        background: #ebebeb url(/images/InformationIcon.png) no-repeat left center;
    }
    /* check icon */
    &.alert-check {
        background: #ebebeb url(/images/CheckIcon.png) no-repeat left center;
    }
    /* error icon */
    &.alert-error {
        background: #ebebeb url(/images/ErrorIcon.png) no-repeat left center;
    }
    /* error icon */
    &.alert-warning {
        background: #ebebeb url(/images/WarningIcon.png) no-repeat left center;
    }
    /* success icon */
    &.alert-success {
        background: #ebebeb url(/images/CheckIcon.png) no-repeat left center;
    }

    .description {
        color: #000;

        h1 {
            font-weight: bold;
            color: #000;
            @extend .h4;
            margin-bottom: 0.15rem;

            a {
                font-weight: bold;
                color: #000;
                margin-bottom: 0px;
            }
        }

        div, p {
            color: #000;
            margin-bottom: 10px;
        }

        ul, p {

            &:last-of-type {
                margin-bottom: 0px;
            }

            li {
                a {
                    font-weight: bold;

                    @include hover-focus {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
    /* Order Option Boxes */
    .option-box {
        width: 100%;
        min-width: 120px;
        max-width: 120px;
        margin: 10px !important;
        border-radius: 0;
        border: none !important;
        cursor: pointer;

        &.disabled {
            opacity: 0.6 !important;
        }

        .option-box-bg {
            background-color: #d9d9d9;
            padding: 5px;

            .card-header {
                background: #fff;
                min-height: 100px;
                width: 100%;
                padding: 0px;
                border-radius: 0;
                border: none;
                outline: 1px solid #fff;

                .card-header-text {
                    font-size: 22px;

                    &.long-heading {
                        font-size: 16px
                    }

                    .sub-heading {
                        font-size: 14px;
                    }
                }
            }

            .card-body {
                padding: 8px 0 3px 0;

                .card-text {
                    font-size: 12px;
                }
            }
        }

        .card-description {
            padding: 10px 0;
        }

        &.selected {
            .option-box-bg {
                background-color: $secondary;

                .card-header {
                    color: $secondary-alt;

                    .card-header-text {
                        color: $secondary-alt;

                        .sub-heading {
                            color: $secondary-alt;
                        }
                    }
                }

                .card-body {

                    .card-text {
                        color: #fff;
                    }
                }
            }

            .card-description {
                padding: 10px 0;
                color: $secondary-alt;
            }
        }

        &.has-image {
            .option-box-bg {
                .card-header {
                    color: #fff;
                    background-size: cover !important;

                    .card-header-text {
                        color: #fff;

                        .sub-heading {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .option-box.option-box-wide {
        min-width: 130px !important;
        max-width: 130px !important;
    }
    // Overrides default bootstrap styling.
    .card-deck {
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    /* option box slider */
    .option-box-carousel {
        opacity: 0;
        -webkit-transition: opacity 0.4s;
        transition: opacity 0.4s;

        &.flickity-enabled {
            opacity: 1;
        }

        .carousel-cell {
            width: auto;
            height: auto;
            margin-right: 11px;
            border-radius: 0px;
            z-index: 10;

            @include media-breakpoint-down(md) {
                //width: calc( ( 100%-30px ) / 4);
            }

            @include media-breakpoint-down(sm) {
                //width: calc( ( 100%-30px ) / 3);
            }

            @include media-breakpoint-down(xs) {
                //width: calc( ( 100%-10px ) / 2);
            }

            .option-box {
                display: block;
                width: 130px;
                max-width: 130px;
                margin: 0 auto;
                z-index: 9;
            }
        }

        .flickity-prev-next-button {
            display: block;
        }

        .flickity-prev-next-button.next {
            right: -10px;
        }

        .flickity-prev-next-button.previous {
            left: -10px;
        }

        .flickity-button-icon {
            fill: #717171;
        }

        .flickity-button {
            pointer-events: auto;
            z-index: 100;
            transition: 0.1s ease;
        }

        .flickity-button:disabled {
            pointer-events: auto;
            opacity: 0;
            z-index: 0;
            width: 0;
            height: 0;
        }

        &.diamond-covers {
            width: 414px;

            @include media-breakpoint-down(sm) {
                width: auto;
            }
        }
    }
    /* homepage testimonials */
    .testimonials {
        background: #dcdcdc;
        color: #4e4e4e;

        .testimonial-sm-bg {
            background-image: url(/images/footer_testimonials.png);
            background-repeat: no-repeat;
            background-position: top 37px center;
            background-size: 148px;
            height: 169px;
        }

        .testimonial-bg {
            background-image: url(/images/footer_testimonials.png);
            background-repeat: no-repeat;
            background-position: bottom 0px left 15px;
            height: 300px;

            @include media-breakpoint-down(md) {
                background-size: 160px;
            }

            @include media-breakpoint-down(sm) {
                background-size: 160px;
                background-position: top 15px left 15px;
                background-image: none;
            }

            .testimonials-wrapper {
                position: relative;
                height: 300px;
                overflow: hidden;

                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    margin-left: 350px;
                    height: 100000px;
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    left: 0;

                    @include media-breakpoint-down(md) {
                        margin-left: 180px;
                    }

                    @include media-breakpoint-down(sm) {
                        margin-left: 0px;
                    }

                    li {
                        float: left;
                        clear: left;
                        height: 300px;
                        overflow: hidden;

                        p {
                            font-size: 14px;

                            @include media-breakpoint-down(md) {
                            }

                            @include media-breakpoint-down(sm) {
                                font-size: 12px;
                            }

                            &:last-of-type {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
    /* secondary page testimonial */
    .testimonial {
        padding-top: 25px;

        .name {
            font-weight: bold;
            text-transform: none;
        }

        .back-to-top-container {
            position: relative;
            width: 100%;
            height: 100%;
            margin-top: 52px;

            .back-to-top {
                position: absolute;
                top: -13px;
                right: 0px;

                .btn-testimonial {
                    background-color: #fff;
                    border-color: #fff;
                    padding-left: 20px;
                    color: #aeaeae;
                    text-transform: none;

                    @include hover-focus {
                        color: $primary;
                        text-decoration: none;
                        background-color: none;
                    }
                }
            }
        }
    }
    /* home header carousel */
    .slideshow {
        position: relative;
        background-color: #d0caca;
        background: url(/images/header_bg.png) repeat-x bottom;
        width: 100%;
        height: 100%;
        /* Hide the images by default */
        .slide {
            position: absolute;
            top: 0px;
            left: 0px;
            display: none;
            z-index: 7;
            width: 100%;
            height: 100%;

            .container {
                position: relative;
                height: 100%;
                padding: 0;
            }

            &.next-slide {
                display: block;
                z-index: 8;
            }

            &.current-slide {
                display: block;
                z-index: 9;
            }

            &.pricing.visible {
                background: url(/images/HomeSlides/slide_bg_pricing_1.png) no-repeat top center;
                background-size: cover;
            }
        }

        .controls {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .container {
                position: relative;
                width: 100%;
                height: 100%;
                /* Next & previous buttons */
                .home-slides-prev, .home-slides-next {
                    cursor: pointer;
                    position: absolute;
                    top: 50%;
                    z-index: 10;
                    width: auto;
                    margin-top: -29px;
                    padding: 10px 18px;
                    color: #464646;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 1.1;
                    transition: 0.6s ease;
                    border-radius: 0;
                    background-color: #ccc;
                }
                /* Position the "prev button" to the left */
                .home-slides-prev {
                    left: -100px;

                    @media (max-width: 1220px) {
                        left: -47px;
                    }

                    @include media-breakpoint-down(xs) {
                        display: none;
                        left: 0px;
                    }
                }
                /* Position the "next button" to the right */
                .home-slides-next {
                    right: -100px;

                    @media (max-width: 1220px) {
                        right: -47px;
                    }

                    @include media-breakpoint-down(xs) {
                        display: none;
                        right: 0px;
                    }
                }
                /* On hover, add a black background color with a little bit see-through */
                .home-slides-prev:hover, .home-slides-next:hover {
                    background-color: #aeaeae;
                    text-decoration: none;
                }
            }
        }

        .slide-dots {
            position: absolute;
            left: 0;
            bottom: 7px;
            width: 100%;
            text-align: center;
            z-index: 10;

            @include media-breakpoint-down(xs) {
                bottom: 5px;
            }

            a {
                padding: 3px;
            }
        }
    }
    /* home mobile header carousel */
    .slideshow-mobile {
        .mobile-content-box {
            border: 8px solid #ff0000;
            background: #fff;
            color: #4e4e4e;
            font-size: 1rem;
            font-style: italic;
            display: block;
            min-height: 175px;

            @include media-breakpoint-down(sm) {
                min-height: 205px;
            }

            a {
                color: #4e4e4e;
            }

            img {
                vertical-align: middle;
                float: left;
                margin-right: 13px;
            }

            span {
                font-size: 18px;
            }

            .mobile-box-heading {
                color: #ff0000;
                font-size: 1.85rem;
                text-transform: uppercase;
            }

            .mobile-box-subheading {
                color: #4e4e4e;
                font-size: 1.35rem;
                text-transform: uppercase;
            }
        }

        .mobile-content-box-alt {
            .mobile-box-heading {
                color: #4e4e4e;
                font-size: 1.35rem;
            }

            .mobile-box-subheading {
                color: #ff0000;
                font-size: 1.85rem;
            }
        }

        .img-alt {
            transform: translateX(-47%);
            width: 275vw;
        }

        .video-nevada {
            width: 152vw;
            transform: translate(-4%, -5%);
        }

        .video-alt {
            transform: translateX(-42%);
            width: 202vw;
        }

        .carousel-control.left,
        .carousel-control.right {
            color: $secondary;
            background-image: none;
        }

        .carousel-item {
            overflow: hidden;
        }

        .carousel-indicators {
            position: relative;
            z-index: 1;
            text-align: center;
        }

        .carousel-indicators li {
            background-color: lightgray;
        }

        .carousel-indicators .active {
            background-color: $secondary;
        }
    }
    /* FAQs */
.faq-accordion {
    margin-bottom: 40px;

    h2.faq-section {
        background-color: #e2e2e2;
        padding: 8px 0 8px 20px;
        margin-bottom: 10px;
    }

    .card {
        border-radius: 0;
        border: none;

        .card-header {
            border-radius: 0;
            border-bottom: 0px;
            background-color: transparent;
            padding: 0.50rem 1.25rem;

            .btn-faq {
                display: block;
                position: relative;
                padding-left: 35px;
                font-size: 18px;
                word-wrap: break-word;
                white-space: normal;
                width: 100%;
                text-align: left;
                line-height: 1.2;
                text-transform: none;
                @extend .link-primary;
                @extend .link-offset-2;
                @extend .link-underline-opacity-0;
                @extend .link-underline-opacity-100-hover;

                span {
                    position: absolute;
                    left: 0;
                    display: inline-block;
                    width: 40px;
                    line-height: 1.4;
                }
            }
        }

        .card-body {
            padding: 10px 0 15px 58px;

            a {
                font-size: 18px;
                font-weight: 700;

                @include hover-focus {
                    color: darken($primary, 15%);
                }
            }
        }
    }
}
    /* Hot of the press slider */
    .hop-carousel {

        .carousel-cell {
            width: 31%;
            height: 203px;
            margin-right: 15px;
            border-radius: 0px;

            @include media-breakpoint-down(sm) {
                width: calc( ( 100% - 10px ) / 2);
            }

            @include media-breakpoint-down(xs) {
                width: 100%;
            }


            &.is-selected {
            }

            .hop-container {

                img {
                    display: block;
                    width: 165px;
                    height: 203px;
                    margin: 0 auto;
                }

                .printed {
                    color: #4e4e4e;
                    font-size: 13px;
                    font-style: italic;
                    text-transform: none;
                    padding-top: 5px;

                    strong {
                        font-size: 22px;
                    }
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .flickity-prev-next-button {
            background: none;
        }

        .flickity-prev-next-button.next {
            right: -10px;
        }

        .flickity-prev-next-button.previous {
            left: -10px;
        }

        .flickity-button-icon {
            fill: #717171;
        }
    }
    /* Hot off the Press Page */
    .hop-wrapper {

        .col {
            max-width: 196px;
        }

        .hop-container {

            img {
                display: block;
                width: 165px;
                height: 203px;
                margin: 0 auto;
            }

            .printed {
                color: #4e4e4e;
                font-size: 13px;
                font-style: italic;
                text-transform: none;
                padding-top: 5px;

                strong {
                    font-size: 22px;
                }
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
    /* How to videos thumbnail slider / flickity */

    .you-tube-gallery-wrapper {
        .embed-border {
            margin-bottom: 5px;
            border-bottom: 1px solid #b9b9b9;
        }

        .you-tube-gallery {
            position: relative;
            width: 100%;
            height: auto;

            .Slide {
                position: absolute;
                top: 0px;
                left: 0;
                width: 100%;
                height: auto;
                z-index: 1;
                margin: 0px;
                padding: 0px;
                background-color: #000000;
                background-image: url(/images/YouTubeGallery/loading.png);
                background-repeat: no-repeat;
                background-position: center center;
                border: 1px solid #b9b9b9;

                &.active {
                    z-index: 99;
                }
            }
        }

        .how-to-video-thumbnail-carousel {

            .thumbnail-container {
                cursor: pointer;

                &.active {
                    .figure-caption {
                        color: $primary;
                    }
                }

                img {
                    display: block;
                    width: 100%;
                    max-width: 234px;
                    height: auto;
                    margin: 0 auto;
                    padding: 3px;
                    border: 1px solid #b9b9b9;

                    &:hover {
                        border: 1px solid $primary;
                    }
                }

                .figure-caption {
                    color: #4e4e4e;
                    font-size: 14px;
                    text-transform: normal;
                    padding: 5px 5px 0px 5px;

                    strong {
                        font-size: 22px;
                    }
                }

                &:hover {
                    text-decoration: none;

                    .caption {
                        color: $primary;
                    }
                }
            }
            /*.flickity-prev-next-button {
        }

        .flickity-prev-next-button.next {
            top:35%;
            right: 15px;

            @include media-breakpoint-down(md) {
                top: 25%;
            }

            @include media-breakpoint-down(xs) {
                top: 35%;
            }
        }

        .flickity-prev-next-button.previous {
            top:35%;
            left: 15px;

            @include media-breakpoint-down(md) {
                top: 25%;
            }

            @include media-breakpoint-down(xs) {
                top: 35%;
            }
        }

        .flickity-button-icon {
            fill: #717171;*/
            /*}*/
        }

        .video-summary {
            display: none;
            position: relative;
            z-index: 1000;
            padding: 5px 0px 0px 0px;
        }

        .VideoHR {
            width: 835px;
            height: 1px;
            background-color: #d0d0d0;
            margin-bottom: 10px;
        }

        .VideoSummaryHeader {
            font-size: 14px;
            color: #ff0000;
            font-style: italic;
            font-weight: bold;
        }
    }
    /* blockquotes */
    blockquote {
        &.quote {
            color: $primary;
            font-size: 20px;
            background-color: #f0f0f0;
            padding: 50px;
            line-height: 40px;
            font-weight: 300;
            margin: 25px 2rem 25px 2rem;

            @include media-breakpoint-down(sm) {
                font-size: 18px;
                line-height: 30px;
                margin: 25px 1rem 25px 1rem;
            }
        }
    }
    /* box-shadow-adds rounded corners, border, padding, and box-shadow */
    .box-shadow {
        border: 1px solid #d1d1d1;
        border-radius: 1px;
        box-shadow: #a1a1a1 0px 0px 5px;
        padding: 35px;

        :last-child {
            margin-bottom: 0px;
        }

        &.padding-sm {
            padding: 15px;
        }
    }
    /* The ordering process page */
    .step-container {

        h4 {
            font-size: 15px;
            color: $primary;
        }

        .step-button-group {
            .btn {
                margin: 0 5px 5px 0;
            }
        }
    }

    .step {
        padding-top: 30px;

        .back-to-top-container {
            position: relative;
            width: 100%;
            height: 100%;
            margin-top: 65px;

            .back-to-top {
                position: absolute;
                top: -13px;
                right: 0px;

                .btn-step {
                    background-color: #fff;
                    border-color: #fff;
                    padding-left: 20px;
                    color: #aeaeae;
                    text-transform: none;

                    @include hover-focus {
                        color: $primary;
                        text-decoration: none;
                        background-color: none;
                    }
                }
            }
        }
    }
    /* figures */
    .figure {
        display: table;

        @include media-breakpoint-down(sm) {
            width: auto;
            margin: 0 auto;
        }

        img {
            vertical-align: middle;
        }

        .caption {
            display: table-caption;
            caption-side: bottom;
            font-style: italic;
            color: #777;
            font-size: 13px;
            padding: 10px 2px 0px 2px;
        }
    }
    /* Thumnbail slider ( reformating page ) */
    .reformat-thumbnail-carousel {

        .carousel-cell {
            width: 16%;
            height: auto;
            margin-right: 5px;
            border-radius: 0px;
            cursor: pointer;

            @include media-breakpoint-down(md) {
                width: calc( (100% - 5px ) / 4);
                margin-right: 0px;
            }

            @include media-breakpoint-down(sm) {
                width: calc( ( 100% - 5px ) / 3);
            }

            @include media-breakpoint-down(xs) {
                width: calc( ( 100% - 5px ) / 1);
            }


            @include hover-focus {
                img {
                    border: 3px solid $primary;
                }
            }

            &.active {
                img {
                    border: 3px solid $primary;
                }
            }

            img {
                display: block;
                width: auto;
                height: 209px;
                margin: 0 auto;
                padding: 1px;
                background: #fff;
                border: 3px solid #d8d8d8;
            }
        }

        .flickity-prev-next-button {
        }

        .flickity-prev-next-button.next {
            right: 22px;
        }

        .flickity-prev-next-button.previous {
            left: 22px;
        }

        .flickity-button-icon {
            fill: #717171;
        }
    }

    .reformat-thumbnail-details {
        display: none;

        &.active {
            display: block;
        }

        .reformat-design-images {
            .image-container {

                @include media-breakpoint-up(lg) {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 20% !important;
                    flex: 0 0 20% !important;
                    max-width: 20%;
                }

                div {

                    img {
                        border: 1px solid #d8d8d8;
                        width: 100%;
                    }

                    .caption {
                        padding: 8px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    /* New Formating Options */
    .new-options-container {
        div {
            img {
                display: block;
                width: 100%;
                border: 3px solid #fff;
                cursor: pointer;
                padding: 3px;
            }
        }
    }
    /* Blog Page */
    .mb-12 {
        margin-bottom: 12px;
    }

    .mt-17 {
        margin-top: 17px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    #blog {
        width: auto !important;

        thead {
            tr {
                th {
                    display: none;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 0;
                    border: 0;

                    &.date {
                        display: none;
                    }
                }
            }
        }

        .blog-header {
            h1 {
                a {
                    text-decoration: none;
                }
            }
        }

        .blog-hr {
            border-top: 1.5px dashed;
            margin: 30px 0 20px 0;
        }

        .blog-details-hr {
            border-top: 1.5px dashed;
            margin: 30px 0 30px 0;
        }
    }

    .blog-items-container {

        ul {
            list-style-type: none;
        }

        li {
            display: inline-block;
        }

        th.sorting_asc {
            display: none;
        }
    }

    .recent-posts-container {
        a {
            text-decoration: none;
        }
    }
    /* Order History */
    .order-details-full {
        display: none;
    }

    .order-detail-container-old {
        margin-top: 20px;
        margin-bottom: 20px;

        &.first {
            margin-top: 20px;
        }

        &.last {
            @include media-breakpoint-down(sm) {
                margin-bottom: 0px;
            }
        }

        &.last-container {
            margin-bottom: 0px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 0px;
        }

        .order-detail-box {
            padding: 26px 0px 26px 0px;
            background-color: #f0f0f0;

            &.white {
                background-color: #fff;
                padding: 0px 0px;
            }

            &.box-padding {
                padding: 26px 12px 26px 12px;
                margin-bottom: 30px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 20px;
                }
            }

            &.background-dark {
                background-color: #aeaeae;
            }

            table.details-table {

                tr {
                    th {
                        border-right: 1px solid #c0c0c0;
                        vertical-align: top;
                        color: #8f8f8f;
                        font-size: 16px;
                        padding: 4px 12px;
                    }

                    td {
                        font-size: 15px;
                        padding: 4px 12px;
                    }
                }

                &.no-borders {
                    th {
                        border: none;
                        padding: 4px 0px;
                    }

                    td {
                        border: none;
                        padding: 4px 0px;
                    }
                }

                &.no-side-padding {

                    th {
                        padding: 0px 12px;
                    }

                    td {
                        padding: 0px 12px;
                    }
                }

                &.order-totals {
                    th {
                        color: #fff;
                        font-size: 20px;
                        font-weight: bold;
                    }

                    td {
                        color: #fff;
                        font-size: 20px;

                        &.white-divider {
                            padding: 0px;

                            hr {
                                height: 2px;
                                padding: 0px;
                                margin: 9px 0px 7px 0px;
                                background: #fff;
                            }
                        }
                    }
                }

                caption {
                    margin: 0 12px 6px 12px;
                    padding: 0 0 4px 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: $primary;
                    border-bottom: 1px solid #c0c0c0;
                    text-align: left;

                    &.caption-top {
                        caption-side: top;
                    }
                }
            }
        }

        .foot-note {
            font-weight: bold;
            font-size: 11px;
            margin-top: 10px;
            margin-bottom: 32px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 0px;
            }
        }
    }

    .dotted-red-border {
        margin: 5px 0px 15px 0px;
        padding: 15px;
        border: 2px dashed #ff0000;
    }

    .RecentlyPrintedBooks {
        margin-bottom: 20px;

        .item {
            background: #fff;
            border-top: 1px solid #cfcfcf;
            padding: 5px;
            padding-top: 20px;
            margin-top: 0px;
            margin-bottom: 20px;
            min-height: 120px;

            .book-order-item {
                padding: 10px;

                .cover {
                    position: relative;
                    width: 144px;

                    .text {
                        position: absolute;
                        top: 60px;
                        left: 0;
                        text-align: center;
                        width: 100%;
                        font-size: 12px;
                        line-height: normal;
                        font-weight: bold;
                        color: #747474;
                        z-index: 12;
                    }

                    img {
                        display: block;
                        padding: 5px;
                        background: #fff;
                        border: 1px solid #c1c1c1;
                        box-shadow: #ccc 0px 0px 5px;
                    }
                }

                .copy {
                    padding-top: 15px;

                    @include media-breakpoint-down(md) {
                        padding-top: 5px;
                    }

                    .list-unstyled {
                        @include media-breakpoint-down(md) {
                            margin-bottom: 0px;
                            width: 100%;
                        }

                        li {
                            a {
                                color: #4e4e4e;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            .divider {
                position: relative;
                height: 100%;
                padding: 10px 10px 10px 30px;

                @include media-breakpoint-down(sm) {
                    padding: 10px 10px 0px 10px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: -4px;
                    z-index: 1;
                    border-left: 2px solid #e5e5e5;

                    @include media-breakpoint-down(md) {
                        left: -7px;
                    }

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                .no-opt-in {
                    opacity: 0.5;
                }

                .icon {
                    img {
                        width: 32px;
                        margin-bottom: 5px;
                    }
                }

                .list-unstyled {
                    li {
                        a {
                            font-weight: bold;
                            color: #464646;
                        }
                    }
                }
            }
        }
    }
    /* order history new */
    .order-details-nav {

        .nav-item:first-of-type {
            .nav-link {
                border-left: 1px solid #4e4e4e;
            }
        }

        .nav-link {
            color: #4e4e4e;
            background-color: #f0f0f0;
            border-color: #4e4e4e;
            border-left: none;
            font-size: 14px;
            font-weight: bold;
            border-top-left-radius: 0.45rem;
            padding: 0.3rem 0.3rem;
            border-top-right-radius: 0.45rem;

            @include hover-focus {
                color: #fff;
                background-color: darken($primary, 10%);
                border-color: #4e4e4e;
            }
        }

        .nav-link.active, .nav-tabs .nav-item.show {
            color: #fff;
            background-color: $primary;
            border-color: #4e4e4e;

            @include hover-focus {
                color: #fff;
                background-color: $primary;
                border-color: #4e4e4e;
            }
        }
    }

    .order-details-tab-content {
        border-left: 1px solid #4e4e4e;
        border-right: 1px solid #4e4e4e;
        border-bottom: 1px solid #4e4e4e;

        .tab-pane {
            padding: 1rem;
        }
    }

    .order-detail-container {
        h2 {
            margin-bottom: 10px;
        }

        .shipping-destination:last-of-type {
            margin-bottom: 0px !important;
        }

        .order-detail-box {
            padding: 16px 0px 16px 0px;
            background-color: #f0f0f0;

            &.white {
                background-color: #fff;
                padding: 0px 0px;
            }

            &.background-dark {
                background-color: #aeaeae;
            }

            &.box-padding {
                padding: 26px 12px 26px 12px;
                margin-bottom: 1.5rem;
            }

            table.details-table {

                tr {
                    th {
                        border-right: 1px solid #c0c0c0;
                        vertical-align: top;
                        color: #8f8f8f;
                        font-size: 16px;
                        padding: 4px 12px;
                    }

                    td {
                        font-size: 16px;
                        padding: 4px 12px;
                    }
                }

                &.no-borders {
                    th {
                        border: none;
                        padding: 4px 0px;
                    }

                    td {
                        border: none;
                        padding: 4px 0px;
                    }
                }

                &.no-side-padding {

                    th {
                        padding: 0px 12px;
                    }

                    td {
                        padding: 0px 12px;
                    }
                }

                &.order-totals {
                    th {
                        color: #fff;
                        font-size: 16px;
                        font-weight: bold;
                    }

                    td {
                        color: #fff;
                        font-size: 16px;

                        &.white-divider {
                            padding: 0px;

                            hr {
                                height: 2px;
                                padding: 0px;
                                margin: 9px 0px 7px 0px;
                                background: #fff;
                            }
                        }
                    }
                }

                caption {
                    margin: 0 12px 6px 12px;
                    padding: 0 0 4px 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: $primary;
                    border-bottom: 1px solid #c0c0c0;
                    text-align: left;

                    &.caption-top {
                        caption-side: top;
                    }
                }
            }
        }

        .foot-note {
            font-weight: bold;
            font-size: 11px;
        }
    }
    /* Order Stepper */
    .order-stepper {
        .col, .col-auto {
            position: relative;
            &:before {
                top: 6px;
                bottom: 0;
                position: absolute;
                content: " ";
                width: 100%;
                height: 3px;
                background-color: #aeaeae;
            }

            &:after {
                top: -4px;
                bottom: 0;
                position: absolute;
                content: " ";
                width: 22px;
                height: 22px;
                border-radius: 50%;
                border: 5px solid #fff;
                background-color: #aeaeae;
            }

            &.completed {
                &:before {
                    background-color: #515151;
                }

                &:after {
                    background-color: #515151;
                }

                div {
                    color: #515151;
                }
            }

            &.active {
                &:before {
                    background-color: #aeaeae;
                }

                &:after {
                    background-color: $primary;
                }

                div {
                    color: $primary;
                }
            }

            &:last-of-type {
                &:before {
                    visibility: hidden;
                }
            }

            div {
                line-height: 1.2;
                margin-top: 20px;
                margin-left: 5px;
                font-weight: bold;
                font-size: 14px;
                color: #aeaeae;
                padding-right: 10px;
            }

            @include media-breakpoint-down(xs) {
                div {
                    margin-bottom: 10px;
                }
            }
        }
    }
    /* Quick Price */
    .order-process {

        .order-group {
            margin-bottom: 40px;

            .order-option {
                margin-bottom: 10px;

                .heading {

                    .order-heading {
                        padding: 8px 12px;
                        background: #e2e2e2;
                        color: #a2a2a2;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 16px;
                        cursor: not-allowed;

                        @include media-breakpoint-down(xs) {
                            font-size: 13px;
                        }

                        &.active {
                            background: #4e4e4e !important;
                            color: #fff;
                            cursor: pointer;

                            .guide {
                                background: url(/images/order/guide_icon_alt.png) no-repeat left;
                            }
                        }

                        &.completed {
                            background: #717171;
                            color: #fff;
                            cursor: pointer;

                            .guide {
                                background: url(/images/order/guide_icon_alt.png) no-repeat left;
                            }
                        }

                        .order-check {
                            width: 15px;
                            height: 15px;
                            background: url(../../images/order_check.png) no-repeat;
                        }

                        .guide {
                            background: url(/images/order/guide_icon.png) no-repeat left;
                            display: inline-block;
                            height: 21px;
                            padding-left: 28px;
                            cursor: pointer;
                            font-size: 14px;
                            font-weight: bold;
                            line-height: 23px;
                            text-transform: none;
                        }
                    }
                }

                .order-section {
                    margin-bottom: 0.90rem;

                    .order-section-content {
                        padding: 15px;

                        .quick-price-row {
                            padding-right: 10%;

                            @include media-breakpoint-down(sm) {
                                padding-right: 0;

                                h1 {
                                    font-size: 20px;
                                }

                                h2 {
                                    font-size: 20px;
                                }

                                h3 {
                                    font-size: 16px;
                                }

                                h4 {
                                    font-size: 20px;
                                }
                            }
                        }
                    }

                    .order-section-footer {
                        padding: 15px;
                    }
                }
            }
        }
    }

    .order-sidebar-column {
        padding-left: 5px !important;

        .order-sidebar {
            width: 221px;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 1020;


            .sidebar-groups {

                .order-heading {
                    background-color: #4e4e4e;
                    color: #fff;
                    padding: 15px 10px;
                    text-transform: uppercase;
                    font-size: 16px;
                    cursor: pointer;

                    span {
                        line-height: 1.5;
                    }
                }

                .order-section {
                    background: #717171;
                    color: #fff;
                    padding: 15px 10px;

                    .order-section-content {

                        dl {
                            font-size: 11px;
                            padding: 0px;
                            margin: 0px;

                            dt {
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                text-transform: uppercase;
                                position: relative;
                                margin: 0px;
                                padding-right: 10px;
                                padding: 5px 10px 5px 0px;
                                overflow: hidden;

                                &[data-step] {
                                    cursor: pointer;
                                }
                            }

                            dd {
                                padding: 5px 10px 5px 0px;
                                margin: 0px;

                                &[data-step] {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }

            .order-totals {

                dl {
                    padding: 0px;
                    margin: 0px;

                    dt {
                        margin: 0px;
                        padding: 5px 10px 5px 0px;
                        font-weight: normal;

                        &.bold-md {
                            font-weight: bold;
                            font-size: 16px;
                        }

                        &.bold-lg {
                            font-weight: bold;
                            font-size: 20px;
                        }

                        &.w-100 {
                            padding: 0px;
                        }
                    }

                    dd {
                        margin: 0px;
                        padding: 5px 0px 5px 0px;
                        font-weight: normal;

                        &.bold-md {
                            font-weight: bold;
                            font-size: 16px;
                        }

                        &.bold-lg {
                            font-weight: bold;
                            font-size: 20px;
                        }

                        span.strike-through {
                            text-decoration: line-through;
                            color: #999;
                        }
                    }
                }

                hr {
                    margin: 3px 0;
                }
            }
        }
    }

    .page-total {
        width: 100%;
        text-transform: uppercase;
        line-height: 1.3;

        strong {
            color: $secondary-alt;
            font-size: 26px;
            display: block;

            @include media-breakpoint-down(sm) {
                font-size: 22px;
            }

            @include media-breakpoint-down(xs) {
                font-size: 18px;
            }
        }
    }

    .book-total {
        width: 100%;
        text-transform: uppercase;
        line-height: 1.3;

        strong {
            color: $secondary-alt;
            font-size: 26px;
            display: block;

            @include media-breakpoint-down(sm) {
                font-size: 22px;
            }

            @include media-breakpoint-down(xs) {
                font-size: 18px;
            }
        }
    }

    .tab-total {
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 49px;

        strong {
            display: inline-block;
            color: $primary;
            font-size: 50px;
            vertical-align: middle;

            @include media-breakpoint-down(sm) {
                line-height: 44px;
                font-size: 45px;
            }

            @include media-breakpoint-down(xs) {
                line-height: 33px;
                font-size: 34px;
            }
        }
    }

    ul.production-time {
        li {
            padding: 5px 0 0 0;
            margin: 0px;

            label {
                cursor: pointer;
                display: block;
            }

            &.selected {
                .check {
                    color: #fff;
                }
            }
        }

        .check {
            background: #939393;
            color: #939393;
            display: inline-block;
            width: 18px;
            height: 18px;
            line-height: 18px;
            text-align: center;
            margin-left: 5px;
        }
    }

    #diamond-cover-region {

        .diamond-cover-heading {
            h1 {
                @include media-breakpoint-down(sm) {
                    font-size: 26px;
                }

                @include media-breakpoint-down(xs) {
                    font-size: 20px;
                }
            }
        }
    }

    #quick-price-mobile-menu {
        position: fixed;
        top: 66px;
        bottom: 0px;
        right: -287px;
        width: 287px;
        background-color: #cdcdcd;
        z-index: 1025;
        overflow-y: auto;

        .order-totals {

            dl {
                padding: 0px;
                margin: 0px;

                dt {
                    margin: 0px;
                    padding: 5px 10px 5px 0px;
                    font-weight: normal;

                    &.bold-md {
                        font-weight: bold;
                        font-size: 16px;
                    }

                    &.bold-lg {
                        font-weight: bold;
                        font-size: 20px;
                    }

                    &.w-100 {
                        padding: 0px;
                    }
                }

                dd {
                    margin: 0px;
                    padding: 5px 0px 5px 0px;
                    font-weight: normal;

                    &.bold-md {
                        font-weight: bold;
                        font-size: 16px;
                    }

                    &.bold-lg {
                        font-weight: bold;
                        font-size: 20px;
                    }

                    span.strike-through {
                        text-decoration: line-through;
                        color: #999;
                    }
                }
            }

            hr {
                margin: 3px 0;
            }
        }
    }

    #quick-price-tab {
        position: fixed;
        top: 50vh;
        right: 0px;
        background-color: red;
        color: #fff;
        width: 34px;
        cursor: pointer;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-left: 1px solid #fff;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        z-index: 107;

        div {
            -webkit-writing-mode: vertical-lr;
            -ms-writing-mode: tb-lr;
            writing-mode: vertical-lr;
            text-orientation: sideways;
            margin: 0 auto;
            font-size: 18px;

            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }

            @include media-breakpoint-down(xs) {
                font-size: 14px;
            }
        }
    }
    /* Book Size Options */
    .size-choices {
        ul {
            li {
                cursor: pointer;
                font-weight: bold;

                &.active {
                    color: $secondary-alt;

                    &:hover {
                        color: $secondary-alt;
                    }
                }

                &:hover {
                    color: #818181;
                }
            }
        }
    }

    .size-chart {
        background: url(/images/order/size/chart_bg.png);
        background-repeat: no-repeat;
        background-size: contain;
        width: 524px;
        height: 511px;
        position: relative;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
            background: none;
            width: 100%;
            height: 100%;
            position: static;
            overflow: visible;
        }

        .selected, .hover {
            position: absolute;
            bottom: 23px;
            left: 29px;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: bottom left;
        }

        .selected {
            z-index: 8;

            &.landscape_11 {
                background-image: url(/images/order/size/size_landscape_11.png);

                &.mobile {
                    width: 185px;
                    height: 147px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.landscape_12 {
                background-image: url(/images/order/size/size_landscape_12.png);

                &.mobile {
                    width: 203px;
                    height: 157px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.landscape_7 {
                background-image: url(/images/order/size/size_landscape_7.png);

                &.mobile {
                    width: 110px;
                    height: 69px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.landscape_8-5 {
                background-image: url(/images/order/size/size_landscape_8-5.png);

                &.mobile {
                    width: 138px;
                    height: 92px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.landscape_9 {
                background-image: url(/images/order/size/size_landscape_9.png);

                &.mobile {
                    width: 148px;
                    height: 101px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.portrait_4-25 {
                background-image: url(/images/order/size/size_portrait_4-25.png);

                &.mobile {
                    width: 79px;
                    height: 140px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.portrait_5-5 {
                background-image: url(/images/order/size/size_portrait_5-5.png);

                &.mobile {
                    width: 103px;
                    height: 168px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.portrait_6 {
                background-image: url(/images/order/size/size_portrait_6.png);

                &.mobile {
                    width: 112px;
                    height: 177px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.portrait_8-5 {
                background-image: url(/images/order/size/size_portrait_8-5.png);

                &.mobile {
                    width: 158px;
                    height: 214px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.portrait_9 {
                background-image: url(/images/order/size/size_portrait_9.png);

                &.mobile {
                    width: 168px;
                    height: 233px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.square_11 {
                background-image: url(/images/order/size/size_square_11.png);

                &.mobile {
                    width: 205px;
                    height: 214px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.square_12 {
                background-image: url(/images/order/size/size_square_12.png);

                &.mobile {
                    width: 223px;
                    height: 232px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.square_5-5 {
                background-image: url(/images/order/size/size_square_5-5.png);

                &.mobile {
                    width: 103px;
                    height: 112px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.square_6 {
                background-image: url(/images/order/size/size_square_6.png);

                &.mobile {
                    width: 112px;
                    height: 121px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.square_8-5 {
                background-image: url(/images/order/size/size_square_8-5.png);

                &.mobile {
                    width: 158px;
                    height: 167px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.square_9 {
                background-image: url(/images/order/size/size_square_9.png);

                &.mobile {
                    width: 167px;
                    height: 177px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }
        }

        .hover {
            z-index: 7;

            &.landscape_11 {
                background-image: url(/images/order/size/size_landscape_11.png);

                &.mobile {
                    width: 185px;
                    height: 147px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.landscape_12 {
                background-image: url(/images/order/size/size_landscape_12.png);

                &.mobile {
                    width: 203px;
                    height: 157px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.landscape_7 {
                background-image: url(/images/order/size/size_landscape_7.png);

                &.mobile {
                    width: 110px;
                    height: 69px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.landscape_8-5 {
                background-image: url(/images/order/size/size_landscape_8-5.png);

                &.mobile {
                    width: 138px;
                    height: 92px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.landscape_9 {
                background-image: url(/images/order/size/size_landscape_9.png);

                &.mobile {
                    width: 148px;
                    height: 101px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.portrait_4-25 {
                background-image: url(/images/order/size/size_portrait_4-25.png);

                &.mobile {
                    width: 79px;
                    height: 140px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.portrait_5-5 {
                background-image: url(/images/order/size/size_portrait_5-5.png);

                &.mobile {
                    width: 103px;
                    height: 168px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.portrait_6 {
                background-image: url(/images/order/size/size_portrait_6.png);

                &.mobile {
                    width: 112px;
                    height: 177px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.portrait_8-5 {
                background-image: url(/images/order/size/size_portrait_8-5.png);

                &.mobile {
                    width: 158px;
                    height: 214px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.portrait_9 {
                background-image: url(/images/order/size/size_portrait_9.png);

                &.mobile {
                    width: 168px;
                    height: 233px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.square_11 {
                background-image: url(/images/order/size/size_square_11.png);

                &.mobile {
                    width: 205px;
                    height: 214px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.square_12 {
                background-image: url(/images/order/size/size_square_12.png);

                &.mobile {
                    width: 223px;
                    height: 232px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.square_5-5 {
                background-image: url(/images/order/size/size_square_5-5.png);

                &.mobile {
                    width: 103px;
                    height: 112px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.square_6 {
                background-image: url(/images/order/size/size_square_6.png);

                &.mobile {
                    width: 112px;
                    height: 121px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.square_8-5 {
                background-image: url(/images/order/size/size_square_8-5.png);

                &.mobile {
                    width: 158px;
                    height: 167px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }

            &.square_9 {
                background-image: url(/images/order/size/size_square_9.png);

                &.mobile {
                    width: 167px;
                    height: 177px;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-position: center top;
                }
            }
        }
    }
    /* END Book Size Options */
    /* Thumnbail slider ( reformating options / how much page ) */
    .reformat-option-carousel {
        opacity: 0;
        -webkit-transition: opacity 0.4s;
        transition: opacity 0.4s;

        &.flickity-enabled {
            opacity: 1;
        }

        .carousel-cell {
            width: auto;
            height: auto;
            margin-right: 6px;
            border-radius: 0px;
            cursor: pointer;

            &.selected {
                img {
                    border: 3px solid $primary;
                }
            }

            img {
                display: block;
                width: auto;
                height: 197px;
                margin: 0 auto;
                padding: 1px;
                background: #fff;
                border: 3px solid #d8d8d8;
            }
        }

        .flickity-prev-next-button {
            display: block;
        }

        .flickity-prev-next-button.next {
            right: -10px;
        }

        .flickity-prev-next-button.previous {
            left: -10px;
        }

        .flickity-button-icon {
            fill: #717171;
        }

        .flickity-button {
            pointer-events: auto;
            z-index: 100;
            transition: 0.1s ease;
        }

        .flickity-button:disabled {
            pointer-events: auto;
            opacity: 0;
            z-index: 0;
            width: 0;
            height: 0;
        }
    }
    /* Guide Content Modal */
#guide-content-modal {
    z-index: 1500;
    --bs-modal-margin: 1.75rem;

    .modal-dialog {
    }

    .modal-content {
        border-radius: 2px;

        .modal-body {
            //padding: 2rem;
        }

        .btn-close {
            // css variables start
            --#{$prefix}btn-close-bg: #{ escape-svg($btn-close-white-bg) };
            --#{$prefix}btn-close-opacity: 1;
            --#{$prefix}btn-close-hover-opacity: 1;
            --#{$prefix}btn-close-focus-shadow: 0 0 0 0.25rem rgba(0 0 0 / 25%);
            --#{$prefix}btn-close-focus-opacity: 1;
            // css variables end
            position: absolute;
            top: 0;
            left: 100%;
            transform: translate(-50%,-50%);
            background-color: #666;
            padding: 0.5em 0.5em;

            @include hover-focus {
                background-color: darken(#666, 15%);
            }
        }
    }
}

    .modal-backdrop {
        display: flex;
        align-items: center;

        span {
            color: #fff;
            font-size: 48px;
            display: block;
            margin: auto
        }
    }

    .modal-backdrop.show {
        opacity: 0.8;
    }
    /* Create a cover design */
    .order-cover-design {
        .color-box {
            display: block;
            width: 68px;
            height: 68px;
            border: 2px solid #e5e5e5;
            margin-bottom: 10px;
            overflow: hidden;
            cursor: pointer;

            span {
                display: inline-block;
                width: 64px;
                height: 64px;
                background: #d8d8d8;
                text-align: center;
                line-height: 100%;
                border: 2px solid #fff;
                color: #fff;
                font-size: 24px;
                font-weight: 600;
                line-height: 68px;
                text-indent: -999px;
            }

            &.selected {
                border: 2px solid #ff0000;

                span {
                    text-indent: -999px;
                }
            }
        }

        .color-chooser {
            overflow: hidden;
            width: 175px;
        }

        .complements > .pull-left, .complements > .pull-right {
            width: 49%;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            small {
                text-transform: uppercase;
                font-size: 76%;
                font-weight: bold;
            }

            span {
                display: block;
                width: 25px;
                height: 25px;
                float: left;
                margin-right: 3px;
                margin-bottom: 3px;
                cursor: pointer;

                @include media-breakpoint-down(sm) {
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }
        }

        .preview {
            .book-template {
                margin-right: 40px;

                @include media-breakpoint-down(sm) {
                    margin-right: 0px;
                    margin-bottom: 20px;
                }
            }

            .color-boxes {
                margin-right: 10px;
            }
        }
    }

    .book-profile {
        .cover {
            text-align: center;
            position: relative;

            .text {
                position: absolute;
                top: 170px;
                left: 0;
                width: 100%;
                font-size: 30px;
                line-height: normal;
                font-weight: bold;
                color: #747474;
                padding: 20px;

                @include media-breakpoint-down(md) {
                    top: 120px;
                }

                @include media-breakpoint-down(sm) {
                    top: 170px;
                }
            }

            img {
                max-width: 380px;
                width: 100%;
            }
        }

        h1 {
            padding: 10px 0;
            text-transform: none;
            font-weight: normal;
        }

        .author {
            color: #999;
            font-size: 18px;
            font-style: italic;
        }

        .about-author {
            color: #999;
            font-size: 12px;
            font-style: italic;
            padding-top: 5px;
        }

        .book-description {
            padding: 10px 0;
        }

        .share-details {
            margin-top: 25px;
        }

        .share {
            text-align: left;
        }

        .share .social img {
            margin-top: 0px;
            margin-right: 3px;
            width: 32px;
        }

        .share .social {
            margin: 5px 0;
            margin-bottom: 25px;
            text-align: left;
        }

        .share .eMail {
            background: #f2f2f2;
            display: inline-block;
            padding: 10px;
            margin-top: 5px;
            width: 260px;
        }

        .share .email {
            color: #464646;
            font-weight: bold;
        }

        .share-disabled {
            cursor: not-allowed;
            opacity: 0.5;
            filter: alpha(opacity=50);
        }

        .share-disabled * {
            cursor: not-allowed;
        }
    }

    .shipping-notice {
        p {
            margin-bottom: 5px;
            line-height: 14px;
        }

        a {
            color: #666;
        }
    }

    .shipping-method-item {
        display: block;
    }

    .shipping-method-item:first-child {
        margin-top: 0;
    }

    .shipping-method-item.disabled {
        color: #ccc;
    }

    span.AllocationLeft {
        margin-left: 10px;
        font-weight: bold;
        font-size: 16px;
        color: green;
    }

    span.AllocationLeft.Error {
        color: red;
    }

    .instructions-col {
        @include media-breakpoint-down(md) {
            border-left: none !important;
        }
    }

    .upload-files-list {
        margin: 10px 0;
    }

    .upload-files-list h5 {
        padding-top: 0;
        margin: 0;
    }

    .upload-files-list h6 {
        padding-top: 0;
        margin: 0;
    }

    .upload-files-list .file-preview {
        padding: 20px 0;
        border-top: 2px solid #e5e5e5;
    }

    .upload-files-list .progress-bar {
        height: 15px;
        background: #fafafa;
        border: 1px solid #333;
        margin: 5px 0;
    }

    .upload-files-list .progress-bar span {
        background: #717171;
        display: block;
        height: 15px;
        width: 0%;
    }

    .strike-through {
        text-decoration: line-through;
    }
    /* Bootstrap Table Styling*/
    .text-red {
        color: $secondary;
    }

    .text-bold {
        font-weight: bold;
    }

    .info-table {
        th {
            border: none;
            padding: 20px;
        }
    }

    .info-table-condensed {
        th {
            padding: 6px 10px 6px 16px;
        }

        td {
            padding: 6px 10px 6px 16px;
        }
    }

    .info-table-header {
        th {
            background: white;
            font-weight: normal;
            text-align: center;
            font-size: 18px;
        }
    }

    .info-table-header-alt {
        th {
            background: #aeaeae;
            ;
            color: white;
            border-bottom: 1px solid #fff;
        }
    }

    .info-table-row {
        th {
            vertical-align: middle;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;

            :not(:first-child) {
                border-left: 1px solid #fff;
            }

            background: #e7e7e7;
        }

        td {
            vertical-align: middle;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            border-left: 1px solid #fff;

            :not(:last-child) {
                border-right: 1px solid #fff;
            }

            background: #f0f0f0;
        }
    }
    /** Home Page Slider*/
    .home-slide-navigation {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        z-index: 999;
        display: flex;
        align-items: center;
    }

    .home-slide-btn {
        cursor: pointer;
        font-size: 15px;
        opacity: 0.5;
        color: #4e4e4e;
        transition: opacity .6s;
    }

    .home-slide-btn:hover {
        opacity: 0.9;
    }

    .home-slide-dots {
        display: flex;

        a {
            box-sizing: content-box;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-right: 3px;
            margin-left: 3px;
            text-indent: -999px;
            cursor: pointer;
            background-color: #4e4e4e;
            background-clip: padding-box;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            opacity: .5;
            transition: opacity .6s ease;
        }

        a.active {
            opacity: .9;
        }
    }

    .home-slide-background-vegas {
        position: absolute;
        top: 0px;
        transform: translateX(-0%);
        height: 100%;
        object-fit: cover;

        @include media-breakpoint-only(xl) {
            transform: translateX(-9%);
        }

        @include media-breakpoint-up(md) {
            left: 50%;
        }
    }

    .home-slide-background {
        position: absolute;
        top: 0px;
        transform: translateX(-50%);
        width: auto;
        height: auto;
        min-width: 100%;
        object-fit: cover;

        @include media-breakpoint-up(md) {
            left: 50%;
        }
    }

    .col-lg-6-vegas-video {
        overflow: hidden !important;

        video {
            transform: translateX(-8%) !important;
        }

        @include media-breakpoint-only(lg) {
            flex: 0 0 55% !important;
            max-width: 55% !important;
        }
    }

    .col-lg-6-vegas-content {
        @include media-breakpoint-only(lg) {
            flex: 0 0 45% !important;
            max-width: 45% !important;
        }
    }


    .home-slide-box-container-vegas {
        max-width: 500px;
        height: 100%;
        align-items: center;
    }

    .home-slide-box-container {
        width: 500px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -100px;
        align-items: center;
    }

    .home-slide-box-container-alt {
        background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 4%, rgba(255,255,255,0.5) 8%, rgba(255,255,255,0.7) 12%, rgba(255,255,255,0.9) 16%, rgba(255,255,255,1) 24%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.3) 4%,rgba(255,255,255,0.5) 8%,rgba(255,255,255,0.7) 12%,rgba(255,255,255,0.9) 16%,rgba(255,255,255,1) 24%,rgba(255,255,255,1) 100%);
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.3) 4%,rgba(255,255,255,0.5) 8%,rgba(255,255,255,0.7) 12%,rgba(255,255,255,0.9) 16%,rgba(255,255,255,1) 24%,rgba(255,255,255,1) 100%);
    }


    .home-slide-box-heading {
        color: #ff0000;
        font-size: 1.85rem;
        text-transform: uppercase;
    }

    .home-slide-box-subheading {
        color: #4e4e4e;
        font-size: 2.5rem;
        text-transform: uppercase;
    }

    .home-slide-box {
        left: 100px;
        position: absolute;
        top: 20%;
    }

    .home-slide-box-alt {
        .home-slide-box-heading {
            color: #4e4e4e;
        }

        .home-slide-box-subheading {
            color: #ff0000;
        }
    }

    .home-slide-box-content {
        display: block;
        color: #4e4e4e;
        font-size: 1rem;
        font-style: italic;

        a {
            color: #4e4e4e;
        }
    }

    .home-slide-box-content-list-item::before {
        content: "\2022";
        padding-right: .25rem;
    }
    /** Slider Styling **/
    .slider-container {
        position: relative;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
        cursor: pointer;

        .slider-view {
            overflow: auto;

            .figure > img {
                max-height: 250px;

                &.active {
                    border: 2px solid red;
                }
                /* Disable ghost image on drag. */
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
            }
        }
    }

    .slider-view {
        /* Hides the scrollbar from being displayed. */
        scrollbar-width: none !important; /* Firefox */
        -ms-overflow-style: none !important; /* IE 10+ */
    }

    .slider-view::-webkit-scrollbar {
        display: none !important; /* Chrome/Safari/Webkit */
        height: 5px !important;
        background-color: #e2e2e2;
    }

/* Hero Page Styles */
$navbar: var(--navbar);

.hero-img-container {
    position: relative;
    overflow: hidden;
    align-items: center;

    &.home {
        @include media-breakpoint-up(md) {
            min-height: calc(75vh - #{$navbar});
            max-height: 94vh;
            display: flex;

            .hero-img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                .home {
                    object-position: 55% 50%;
                }
            }
        }
    }
}

.hero {
    position: relative;

    &-img {
        width: 100%;
        object-fit: cover;

        &-float {
            display: block;
            margin-left: auto;
            margin-right: auto;


            &.tall {
                margin-top: -4rem;

                @include media-breakpoint-up(lg) {
                    margin-top: -2rem;
                }
            }

            &.short {
                margin-top: -5rem;

                @include media-breakpoint-up(lg) {
                    margin-top: -3rem;
                }
            }

            &.tall-alt {
                margin-top: -5rem;

                @include media-breakpoint-up(lg) {
                    margin-top: -6.5rem;
                }
            }
        }

        &-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;

            :is(h1,h2,h3,h4,h5,h6) {
                text-wrap: balance;
            }
        }
    }

    &-breadcrumb {
        --#{$prefix}breadcrumb-padding-x: 1rem;
        --#{$prefix}breadcrumb-padding-y: 0.75rem;
        @include rfs(0.875rem, --#{$prefix}breadcrumb-font-size);
        --#{$prefix}breadcrumb-margin-bottom: 0;
        // breadcrumb variables end
        @extend .breadcrumb;
        position: relative;
        margin-left: 110px;

        .breadcrumb-item > a:is(.text-white) {
            @extend .link-white;
            @extend .link-offset-2;
            @extend .link-underline-opacity-0;
            @extend .link-underline-opacity-100-hover;
        }

        .breadcrumb-item > a:is(.text-dark) {
            @extend .link-dark;
            @extend .link-offset-2;
            @extend .link-underline-opacity-0;
            @extend .link-underline-opacity-100-hover;
        }

        @include media-breakpoint-down(md) {
            display: none !important;
        }

        &.text-dark {
            .breadcrumb-item + .breadcrumb-item {
                &::before {
                    color: $dark;
                }
            }
        }

        &.text-white {
            .breadcrumb-item + .breadcrumb-item {
                &::before {
                    color: $white;
                }
            }
        }
    }
}
    /* Ready to Print */
    .rtp {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        padding-top: 170px;
        padding-bottom: 6rem;
        margin-top: 15rem;

        &-graphic {
            position: absolute;
            bottom: calc(100% - 170px);
            left: 50%;
            transform: translateX(-50%);
            z-index: 50;
            max-width: 480px;
            width: 100%;
            padding: 0 15px;

            img {
                width: auto;
                height: 403px;
            }
        }

        &-bar {
            height: 15px;
            width: 100%;
            background-color: $light-red;
            margin-bottom: 3rem;
        }

        &-bg {
            @each $color, $value in $theme-colors {
                &-#{$color} {
                    position: relative;
                    background-color: #{$value};

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url("/img/ready-to-print-img-overlay.png");
                        @extend .bg-image;
                        opacity: .25;
                    }

                    .overlay {
                        position: relative;
                    }
                }
            }
        }
    }

    @mixin cta($image, $pt, $pb, $mt, $Y, $mb) {
        background: {
            size: cover;
            position: center;
            repeat: no-repeat;
            image: $image;
        }

        position: relative;
        padding-top: $pt;
        padding-bottom: $pb;
        margin-top: $mt;

        &-graphic {
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translate(-50%, $Y);
            z-index: 50;
            max-width: 480px;
            width: 100%;
            padding: 0 15px;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        &-bar {
            position: relative;
            height: 15px;
            width: 100%;
            background-color: $light-red;
            margin-bottom: $mb;
        }
    }
    /* Custom Book Printing */
    .cbp {
        @include cta(url("/img/Custom_book_printing_background.jpg"), 30px, 6rem, 7rem, 60%, 8rem);
    }
    /* Offset Printing CTA */
    .offset-cta {
        @include cta(url("/img/print_my_books_background.jpg"), 0, 4rem, calc(362px + 1rem), 0, 4rem);
    }
    /* Custom Cover Design CTA */
    .custom-cover {
        @include cta(url("/img/Custom_book_printing_background.jpg"), 30px, 5rem, 7rem, 60%, 8rem);
    }
    /* Book Cover Templates CTA */
    .template-cover {
        @include cta(url("/img/Custom_book_printing_background.jpg"), 30px, 4rem, 7rem, 60%, 6rem);
    }
    // Home page Video Ribbon
    .video-ribbon {
        display: inline-block;
        position: absolute;
        z-index: 1000;
        background-color: $secondary;
        right: 0;
        top: 0;
        width: 80px;

        &::after {
            content: "";
            display: block;
            position: absolute;
            height: 50px;
            width: 0;
            border-right: 40px solid $secondary;
            border-left: 40px solid $secondary;
            border-bottom: 30px solid transparent;
        }

        @include media-breakpoint-down(md) {
            left: 0;
            right: auto;
            top: 100px;
            width: auto;
            background-color: transparent;

            &::after {
                content: none;
            }
        }

        .button {
            width: 40px;

            @include media-breakpoint-down(md) {
                width: 60px;
            }
        }
    }


    .table-theme {
        @extend .table;

        @include media-breakpoint-up(xl) {
            table-layout: fixed;
        }

        &-bar {
            height: 5px;
            width: 100%;
            background-color: #dc705e;
            margin-bottom: 0.25rem;
        }

        &.table-striped tbody tr:nth-of-type(odd) {
            background-color: $white;
        }

        &.table-striped tbody tr:nth-of-type(even) {
            background-color: $light-green;
        }

        td, th {
            border-top: none;

            &:first-of-type,
            &:last-of-type {
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
        }

        thead th {
            border-bottom: none;
            font-weight: 400;
        }
    }

    .ps-page-anchor {
        .nav-link:last-of-type {
            border-top: solid 1px $white;

            @include media-breakpoint-up(sm) {
                border-left: solid 1px $white;
                border-top: none;
            }
        }
    }
    // Ebook team images grid
    @mixin team-count($count) {
        flex: math.div(100%, $count);
        max-width: math.div(100%, $count);
    }

    .team-grid {
        @include team-count(4);
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;

        @include media-breakpoint-up(sm) {
            @include team-count(5);
        }

        @include media-breakpoint-up(md) {
            @include team-count(6);
        }

        @include media-breakpoint-up(lg) {
            @include team-count(7);
        }

        @include media-breakpoint-up(xl) {
            @include team-count(8);
        }

        img {
            vertical-align: middle;
            width: 100%;
        }
    }
    //
    // Carousel
    //
    // Carousel controls (prev/next buttons)
    .carousel-fluid {
        position: relative;

        .carousel-fluid-inner {
            position: relative;
            display: flex;
            overflow: hidden;
            touch-action: manipulation;
            opacity: 0;

            &:active {
                cursor: grab;
            }

            &.tns-slider {
                display: block;
                opacity: 1;
            }

            > * {
                backface-visibility: hidden;
            }
        }

        .tns-controls:focus {
            outline: none;
        }

        [data-controls] {
            position: absolute;
            top: 50%;
            border: none;
            width: $carousel-control-size;
            height: $carousel-control-size;
            margin-top: -($carousel-control-size * 0.5);
            transition: all .3s ease-in-out;
            background-color: $carousel-control-bg;
            font-size: math.div($carousel-control-size, 1.5);
            line-height: $carousel-control-size - .125rem;
            text-align: center;
            z-index: 10;
            color: $carousel-control-color;

            &[disabled] {
                opacity: 0 !important;
            }
        }

        [data-controls='prev'] {
            left: 1.75rem;
        }

        [data-controls='next'] {
            right: 1.75rem;
        }

        &:not(.controls-static) {
            [data-controls] {
                opacity: 0;
            }

            [data-controls='prev'] {
                transform: translateX(1rem);
            }

            [data-controls='next'] {
                transform: translateX(-1rem);
            }

            &:hover [data-controls] {
                transform: none;
                opacity: 1;
            }
        }

        &.controls-outside {
            @include media-breakpoint-up(md) {
                padding: 0 $carousel-control-size;
            }

            [data-controls='prev'] {
                left: 0;
            }

            [data-controls='next'] {
                right: 0;
            }
        }

        &.dots-enabled:not(.dots-inside) {
            [data-controls] {
                margin-top: -(($carousel-control-size * 0.5) + $carousel-dot-size + 1.375rem);
            }
        }
        // Carousel dots (nav)
        .tns-nav {
            padding: {
                top: $spacer * .875;
                bottom: $spacer * 0.5;
            }

            text-align: center;
        }

        [data-nav] {
            display: inline-block;
            width: $carousel-dot-size;
            height: $carousel-dot-size;
            margin: 0 .375rem;
            padding: 0;
            transition: width .2s ease-in-out, background-color .2s ease-in-out;
            border: 0;
            border-radius: $carousel-dot-size * 0.5;
            background-color: $carousel-dot-dark-color;

            &:focus {
                outline: none;
            }

            &.tns-nav-active {
                background-color: $carousel-dot-active-color;
            }
        }
    }
    //
    // Tab card styles
    //
    .tab-step {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 2px solid $light-green;
        margin-top: calc(35px + 2rem);

        &::before {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 50%;
            width: 2px;
            height: 35px;
            background-color: $light-green;
            margin-bottom: 1rem;
        }

        &-body {
            padding: 0 1.5rem 1rem;

            @include media-breakpoint-up(md) {
                padding: 0 4rem 1rem;
            }
        }

        &-count {
            border-radius: 50%;
            background-color: $light-green;
            color: $light-red;
            font-size: 3rem;
            line-height: 4rem;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            width: 4rem;
            height: 4rem;
        }

        .multi-col {
            column-count: 3;
            column-gap: 15px;
            column-width: 115px;
        }
    }

    .paper-weight {
        border-radius: 50%;
        color: $light-red;
        font-size: 2rem;
        line-height: 5rem;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        width: 5rem;
        height: 5rem;
        margin-bottom: 1.5rem;
    }

    .icon-pdf {
        background-image: escape-svg($pdf);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 53px;
        display: block;
    }

.list-step {
    padding: 1rem 1rem 1rem 5.5rem;

    &.d-column-2 {
        column-gap: 5.5rem;
    }

    ::marker {
        content: "Step " counter(list-item) ":\00a0";
    }

    &-item {
        @extend .h3;
        font-weight: 700;
        margin-bottom: 2rem;
    }
}
    //
    // PDF card styles
    //
    .pdf-step {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 2px solid $light-green;

        &-body {
            padding: 0 1.5rem 1rem;

            @include media-breakpoint-up(md) {
                padding: 0 4rem 1rem;
            }
        }

        &-count {
            border-radius: 50%;
            background-color: $light-green;
            color: $light-red;
            font-size: 3rem;
            line-height: 4rem;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            width: 4rem;
            height: 4rem;

            &.small {
                font-size: 2rem;
                line-height: 3rem;
                width: 3rem;
                height: 3rem;
            }
        }
    }

    .blog-cta {
        padding: 1.5rem;
        display: flex;

        @include media-breakpoint-up(md) {
            padding: 2rem;
        }

        &-inner {
            width: 100%;

            ::before {
                content: "";
                float: right;
                height: calc(100% - (209px - 1.5rem));

                @include media-breakpoint-up(md) {
                    height: calc(100% - (347px - 2rem));
                }
            }
        }

        &-img {
            float: right;
            clear: right;
            shape-outside: url("/img/mobile/blog-cta.png");
            shape-image-threshold: 0.9;
            shape-margin: 2rem;
            margin-right: -1.5rem;
            margin-bottom: -1.5rem;

            @include media-breakpoint-up(md) {
                shape-outside: url("/img/blog-cta.png");
                margin-right: -2rem;
                margin-bottom: -2rem;
            }
        }

        &-body {
            @include media-breakpoint-up(md) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                height: 100%;
            }
        }
    }

    .message-body {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }

