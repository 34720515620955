// Custom theme cards
//
:root {
    // Card Theme Caret
    --#{$prefix}card-theme-caret-width: #{$card-theme-caret-width};
    --#{$prefix}card-theme-caret: #{escape-svg($card-theme-caret)};
}

// card-theme start
.card-theme {
    // scss-custom-start card-css-vars
    --#{$prefix}card-spacer-y: 2.5rem;
    --#{$prefix}card-title-spacer-y: 1.5rem;
    --#{$prefix}card-border-color: #E5E7EB;
    --#{$prefix}card-border-radius: 0.75rem;
    --#{$prefix}card-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    --#{$prefix}card-inner-border-radius: 0.75rem;
    // scss-custom-end card-css-vars
    box-shadow: var(--#{$prefix}card-box-shadow);
    text-wrap: pretty;
}
// card-theme end

// card-theme hover-animation start
.hover-animation {
    transition: all .5s cubic-bezier(0.22, 0.61, 0.36, 1);
    text-decoration: none;

    figure {
        overflow: hidden;

        img {
            transition: all .3s cubic-bezier(0.39, 0.58, 0.57, 1);
            backface-visibility: hidden;
            transform: scale(1.08);
        }
    }

    .card-title {
        position: relative;
        display: inline-block;

        &::after {
            display: inline-block;
            vertical-align: -0.125em;
            width: var(--#{$prefix}card-theme-caret-width);
            height: var(--#{$prefix}card-theme-caret-width);
            margin-left: 0.2em;
            content: "";
            background-repeat: no-repeat;
            background-size: var(--#{$prefix}card-theme-caret-width);
            background-image: var(--#{$prefix}card-theme-caret);
            transition: all .2s ease-out;
            transform: translateX(0);
        }
    }

    &:hover {
        --#{$prefix}card-border-color: #{$primary};
        --#{$prefix}card-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);

        figure {
            img {
                transform: scale(1);
            }
        }

        .card-title {
            &::after {
                transform: translateX(0.25rem);
            }
        }
    }
}
// card-theme hover-animation end

// card-testimonial start
.card-testimonial {
    // scss-custom-start card-css-vars
    --#{$prefix}card-spacer-y: 1.5rem;
    --#{$prefix}card-spacer-x: 1.5rem;
    --#{$prefix}card-border-width: 0;
    --#{$prefix}card-border-color: transparent;
    --#{$prefix}card-border-radius: 0;
    --#{$prefix}card-inner-border-radius: 0;
    --#{$prefix}card-bg: transparent;
    // scss-custom-end card-css-vars
    text-align: center;
    text-wrap: pretty;
}
// card-testimonial end

// card-steps start
.card-steps {
    // scss-custom-start card-steps-css-vars
    --#{$prefix}card-steps-border-radius: 2rem;
    --#{$prefix}card-steps-color: transparent;
    --#{$prefix}card-steps-radius: calc(var(--#{$prefix}card-steps-border-radius) * 1.25);
    --#{$prefix}card-steps-border: 4px dotted var(--#{$prefix}card-steps-color);
    --#{$prefix}card-steps-spacing: 1.25rem;
    --#{$prefix}card-steps-height: 100%;
    // scss-custom-end card-steps-css-vars
    position: relative;
    padding: calc(0.8975rem + 0.27vw);
    height: var(--#{$prefix}card-steps-height);
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        background: var(--#{$prefix}card-steps-color);
        border-radius: var(--#{$prefix}card-steps-radius);
    }

    &::after {
        z-index: -1;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: var(--#{$prefix}card-steps-border);
        border-radius: var(--#{$prefix}card-steps-radius);
    }

    .arrow {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(-50%, -50%);
        color: var(--#{$prefix}card-steps-color);
        margin-left: 0.25rem;
    }

    @include media-breakpoint-down(sm) {
        &::before {
            top: unset;
            bottom: 0;
            width: 100%;
            height: 50%;
        }

        .arrow {
            top: 100%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(90deg);
            margin: 0.25rem 0 0;
        }
    }

    .card {
        // scss-custom-start card-css-vars
        --#{$prefix}card-border-width: 3px;
        --#{$prefix}card-border-color: #{$lt-gray};
        --#{$prefix}card-border-radius: var(--#{$prefix}card-steps-border-radius);
        --#{$prefix}card-color: #{$black};
        --#{$prefix}card-height: var(--#{$prefix}card-steps-height);
        // scss-custom-end card-css-vars
        box-shadow: -3px 3px 6px 1px rgba(0, 0, 0, .25);

        p {
            font-size: 0.875rem;
            text-wrap: pretty;
        }
    }

    .card-counter {
        position: relative;
        text-align: center;
        color: var(--#{$prefix}card-steps-color);
        padding: 0.25rem 0;
        aspect-ratio: 1/1;
        border: var(--#{$prefix}card-steps-border);
        border-radius: 50%;
        font-weight: 900;
        @extend .display-6;        
    }

    .card-top {
        margin: var(--#{$prefix}card-steps-spacing) auto 0;
    }

    .card-bottom {
        margin: 0 auto var(--#{$prefix}card-steps-spacing);
    }
}
// card-steps end

// Colorizing card-steps by placement
// First
.card-steps-container :nth-child(4n+1) {
    .card-steps {
        --#{$prefix}card-steps-color: #0C163D;
    }
}
// Second
.card-steps-container :nth-child(4n+2) {
    .card-steps {
        --#{$prefix}card-steps-color: #DC705E;
    }
}
// Third
.card-steps-container :nth-child(4n+3) {
    .card-steps {
        --#{$prefix}card-steps-color: #FAB908;
    }
}
// Fourth
.card-steps-container :nth-child(4n+4) {
    .card-steps {
        --#{$prefix}card-steps-color: #6F6952;
    }
}