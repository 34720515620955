.global-cta{
    .hs-error-msg {
        color: white !important;
    }
    .hs-error-msgs {
        color: white !important;
    }

    .hs_error_rollup {
        position: absolute;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .hs-error-msgs label {
        color: white !important;
    }

    .hs-input::placeholder  {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #949494;
        font: normal normal normal 16px/22px Nunito;
        line-height: 1.5em;
    }

    .hs-input:-ms-input-placeholder { 
        /* Internet Explorer 10-11 */
        color: #949494;
        font: normal normal normal 16px/22px Nunito;
        line-height: 1.5em;
    }

    .hs-input::-ms-input-placeholder { 
        /* Microsoft Edge */
        color: #949494;
        font: normal normal normal 16px/22px Nunito;
        line-height: 1.5em;
    }

}