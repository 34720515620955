
/* Market Your Book */
.market-your-book-content {
    top: 10%; 
    left: 10%;
}

.sign-up-btn {
    font-family: $font-family-sans-serif;
    top: 50%;
    left: 10%;
    font-size: large;
    padding-left: 3rem;
    padding-right: 3rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @media screen and (max-width: 768px){
        top: 75%;
    }
}