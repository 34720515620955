// Project utilities
//

$utilities: map-merge(
  $utilities,
  (
    "scroll-margin-top": (
      responsive: true,
      property: scroll-margin-top,
      class: scroll-mt,
      values: map-merge($spacers, (auto: auto))
    ),
    "fw": (
      property: font-weight,
      class: fw,
      values: $font-weights,
    ),
    "font-weight": (
      property: font-weight,
      class: font-weight,
      values: $font-weights,
    ),
    "letter-spacing": (
        property: letter-spacing,
        class: ls,
        values: $letter-spacing,
    ),
     "object-top": (
      responsive: true,
      property: object-position,
      class: object-top,
      values: (
        null: top,
        left: top left,
        right: top right,
      )
    ),
    "object-bottom": (
      responsive: true,
      property: object-position,
      class: object-bottom,
      values: (
        null: bottom,
        left: bottom left,
        right: bottom right,
      )
    ),
    "object-left": (
      responsive: true,
      property: object-position,
      class: object-left,
      values: (
        null: left,
      )
    ),
    "object-right": (
      responsive: true,
      property: object-position,
      class: object-right,
      values: (
        null: right,
      )
    ),
    "column-count": (
        property: column-count,
        values: 2 3 4 5 6,
    ),
    "column-width": (
        property: column-width,
        values: $column-widths,
    ),
    "fill": (
        property: fill,
        values: $theme-colors,
    ),
    "text-shadow": (
        property: text-shadow,
        values: (
        null: $text-shadow,
      )
    ),
    "text-wrap": (
        property: text-wrap,
        values: balance pretty wrap,
    ),
    "margin-right": (
      responsive: true,
      property: margin-right,
      class: mr,
      values: map-merge($spacers, (auto: auto))
    ),
    "margin-left": (
      responsive: true,
      property: margin-left,
      class: ml,
      values: map-merge($spacers, (auto: auto))
    ),
    "padding-right": (
      responsive: true,
      property: padding-right,
      class: pr,
      values: $spacers
    ),
    "padding-left": (
      responsive: true,
      property: padding-left,
      class: pl,
      values: $spacers
    ),
    "text-align": map-merge(
      map-get($utilities, "text-align"),
      (
        values: map-merge(
          map-get(map-get($utilities, "text-align"), "values"),
          (
            left: left,
            right: right,
          ),
        ),
      ),
    ),
    "float": map-merge(
      map-get($utilities, "float"),
      (
        values: map-merge(
          map-get(map-get($utilities, "float"), "values"),
          (
            left: left,
            right: right,
          ),
        ),
      ),
    ),
    "width": map-merge(
      map-get($utilities, "width"),
      ( responsive: true ),
    ),
    "min-viewport-height": map-merge(
      map-get($utilities, "min-viewport-height"),
      (
        values: map-merge(
          map-get(map-get($utilities, "min-viewport-height"), "values"),
          ( 
            25: 25vh,
            33: 33vh,
            50: 50vh,
            75: 75vh,
          ),
        ),
      ),
    ),
    "rounded": map-merge(
      map-get($utilities, "rounded"),
      (
        values: map-merge(
          map-get(map-get($utilities, "rounded"), "values"),
          ( 6: var(--#{$prefix}border-radius-2xl) ),
        ),
      ),
    ),
    "rounded-top": map-merge(
      map-get($utilities, "rounded-top"),
      (
        values: map-merge(
          map-get(map-get($utilities, "rounded-top"), "values"),
          (6: var(--#{$prefix}border-radius-2xl)),
        ),
      ),
    ),
    "rounded-end": map-merge(
      map-get($utilities, "rounded-end"),
      (
        values: map-merge(
          map-get(map-get($utilities, "rounded-end"), "values"),
          (6: var(--#{$prefix}border-radius-2xl)),
        ),
      ),
    ),
    "rounded-bottom": map-merge(
      map-get($utilities, "rounded-bottom"),
      (
        values: map-merge(
          map-get(map-get($utilities, "rounded-bottom"), "values"),
          (6: var(--#{$prefix}border-radius-2xl)),
        ),
      ),
    ),
    "rounded-start": map-merge(
      map-get($utilities, "rounded-start"),
      (
        values: map-merge(
          map-get(map-get($utilities, "rounded-start"), "values"),
          (6: var(--#{$prefix}border-radius-2xl)),
        ),
      ),
    ),
  )
);

// Flex Property
@mixin flex($grow, $shrink, $basis) {
    .flex-#{$grow}-#{$shrink}-#{$basis} {
        flex: $grow $shrink $basis + 0%;
        height: $basis + 0%;
    }
}

@each $count, $value in $column-count {
    .d-column-#{$count} {
        column-count: #{$value};
        column-width: 12rem;
        column-gap: 2rem;

        li, article {
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
        }
    }
}

@include flex(0, 0, 33);
@include flex(0, 0, 66);


.bg-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}