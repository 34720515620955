//
// Custom header styles
// Animate dropdown menu


// Animations for dropdowns
@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.header-search {
    padding: 0.15rem .75rem;
    color: $white;
    background-color: transparent;
    border: 0.15rem solid $secondary;
    border-radius: var(--bs-border-radius);

    &::placeholder {
        color: $white;
    }

    &:focus {
        color: $white;
        background-color: transparent;
        border-color: $secondary;
    }

    &[type='search']::-webkit-search-cancel-button {
        -webkit-appearance: none;
        background-color: rgba($white, 0.5);
        -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
        background-size: 0.875em 0.875em;
        height: 0.875em;
        width: 0.875em;
        cursor: pointer;
        transition: background-color 0.15s ease;

        &:hover {
            background-color: rgba($white, 1);
        }
    }
}

.account-dropdown {
    .dropdown-toggle {
        display: flex;
        align-items: center;

        &::after {
            transition: transform 0.25s ease-in-out;
        }

        &.show {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    .inbox-link {
        @extend .dropdown-item;
    }

    .dropdown-menu {
        --#{$prefix}dropdown-zindex: 1100;
        --bs-dropdown-border-color: #{$secondary};
        --bs-dropdown-border-width: 2px;
        --bs-dropdown-link-color: #{$primary};
        --bs-dropdown-link-hover-color: #{$primary};
        --bs-dropdown-link-hover-bg: transparent;
        --bs-dropdown-link-active-color: #{$primary};
        --bs-dropdown-link-active-bg: transparent;
        --bs-dropdown-link-disabled-color: #{$dropdown-link-disabled-color};

        .dropdown-item {
            white-space: normal;
            text-wrap: balance;
            font-weight: 700;
            text-decoration-color: rgba($primary, 0);
            text-underline-offset: 0.3em;
            text-decoration-line: underline;
            text-decoration-thickness: from-font;
            transition: text-decoration-color 0.2s ease-in-out, text-underline-offset 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);

            &:focus,
            &:hover {
                text-underline-offset: 0.25em;
                text-decoration-color: rgba($primary, 0.5);
            }

            &.show {
                text-underline-offset: 0.25em;
                text-decoration-color: rgba($primary, 1);
            }
        }
    }
}

.books-navbar {
    --bs-navbar-color: --bs-body-color;
    --bs-navbar-hover-color: --bs-body-color;
    --bs-navbar-disabled-color: --bs-body-color;
    --bs-navbar-active-color: --bs-body-color;

    .navbar-nav {
        --bs-nav-link-font-weight: 700;
    }

    .collapse {
        animation: fade-in .25s ease-in;
    }

    .collapsing {
        opacity: 0;
    }

    .dropdown-toggle {
        display: flex;
        align-items: center;

        &::after {
            transition: transform 0.25s ease-in-out;
        }
    }

    .nav-link,
    .dropdown-item {
        text-underline-offset: 0.3em;
        text-decoration-line: underline;
        text-decoration-thickness: from-font;
        transition: text-decoration-color 0.2s ease-in-out, text-underline-offset 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
    }

    .nav-link {
        text-transform: uppercase;
        text-decoration-color: rgba($gray-900, 0);

        &:focus,
        &:hover {
            text-underline-offset: 0.25em;
            text-decoration-color: rgba($gray-900, 0.5);
        }

        &.show {
            text-underline-offset: 0.25em;
            text-decoration-color: rgba($gray-900, 1);

            &.dropdown-toggle {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .inbox-link {
        @extend .btn;
        @extend .btn-secondary;
        @extend .w-100;
    }

    .dropdown-menu {
        --#{$prefix}dropdown-zindex: 1100;
        --bs-dropdown-min-width: 12rem;
        --bs-dropdown-spacer: 0;
        --bs-dropdown-bg: transparent;
        --bs-dropdown-padding-y: 0;
        --bs-dropdown-item-padding-y: 0.5rem;
        --bs-dropdown-link-color: --bs-body-color;
        --bs-dropdown-link-hover-color: --bs-body-color;
        --bs-dropdown-link-hover-bg: transparent;
        --bs-dropdown-link-active-color: --bs-body-color;
        --bs-dropdown-link-active-bg: transparent;
        border: 0;

        .dropdown-item {
            white-space: normal;
            text-wrap: balance;
            font-weight: 700;
            text-decoration-color: rgba($gray-900, 0);

            &:focus,
            &:hover {
                text-underline-offset: 0.25em;
                text-decoration-color: rgba($gray-900, 0.5);
            }

            &.show {
                text-underline-offset: 0.25em;
                text-decoration-color: rgba($gray-900, 1);
            }
        }

        &.show {
            animation: fade-in 0.25s ease-in-out;
        }
    }

    @include media-breakpoint-down(lg) {
        z-index: 1400;

        .navbar-collapse {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background-color: rgba(255,255,255,0.9);
            backdrop-filter: blur(2px);
            padding: 1rem;

            .nav-item {
                border-bottom: solid 1px $orange;

                &:last-of-type {
                    border-bottom: none;
                }
            }
        }

        .dropend {
            .dropdown-toggle {
                &:after {
                    @include caret-down();
                }

                &.show {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }

            .dropdown-menu {
                --bs-dropdown-item-padding-x: 2rem;
            }
        }

        .dropdown-menu {
            --bs-dropdown-border-radius: 0;
            --bs-dropdown-inner-border-radius: 0;
        }

        .header-search {
            color: var(--bs-body-color);
            border: var(--bs-border-width) solid var(--bs-body-color);

            &::placeholder {
                color: var(--bs-body-color);
            }

            &:focus {
                color: var(--bs-body-color);
                border: var(--bs-border-width) solid var(--bs-body-color);
            }

            &[type='search']::-webkit-search-cancel-button {
                background-color: rgba($gray-900, 0.5);

                &:hover {
                    background-color: rgba($gray-900, 1);
                }
            }
        }
    }
    // Desktop Header Styles
    &.navbar-expand {
        @each $breakpoint in map-keys($grid-breakpoints) {
            $next: breakpoint-next($breakpoint, $grid-breakpoints);
            $infix: breakpoint-infix($next, $grid-breakpoints);

            &#{$infix} {
                @include media-breakpoint-up($next) {
                    --bs-navbar-nav-link-padding-x: 0.75rem;
                    --bs-navbar-color: #{$white};
                    --bs-navbar-hover-color: #{$white};
                    --bs-navbar-disabled-color: #{$white};
                    --bs-navbar-active-color: #{$white};

                    .nav-link {
                        text-decoration-color: rgba($white, 0);

                        &:focus,
                        &:hover {
                            text-decoration-color: rgba($white, 0.5);
                        }

                        &.show {
                            text-decoration-color: rgba($white, 1);
                        }
                    }

                    .dropdown-menu {
                        --bs-dropdown-bg: rgba(255,255,255,0.9);
                        --bs-dropdown-padding-y: 0.875rem;
                        --bs-dropdown-item-padding-y: 0.35rem;
                        --bs-dropdown-link-color: #{$primary};
                        --bs-dropdown-link-hover-color: #{$primary};
                        --bs-dropdown-link-active-color: #{$primary};
                        --bs-dropdown-font-size: .875rem;
                        backdrop-filter: blur(2px);
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;

                        .dropdown-item {
                            text-decoration-color: rgba($primary, 0);

                            &:focus,
                            &:hover {
                                text-decoration-color: rgba($primary, 0.5);
                            }

                            &.show {
                                text-decoration-color: rgba($primary, 1);
                            }
                        }
                    }

                    .dropend {
                        .dropdown-menu {
                            --bs-dropdown-bg: #979FAF;
                            border-top-right-radius: var(--bs-dropdown-border-radius);
                        }
                    }
                }
            }
        }
    }
}

@media (prefers-reduced-motion: reduce) {
    .dropdown-menu.show {
        animation: unset !important
    }
}
// Animate custom hamburger menu
.navbar-toggler {
    border: none;
    padding: 0;
    outline: none;

    &:focus {
        box-shadow: none;
    }

    &:not(.collapsed) {
        .hamburger span {
            &:nth-child(1) {
                top: 11px;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 11px;
                width: 0%;
                left: 50%;
            }
        }
    }

    .hamburger {
        width: 30px;
        height: 20px;
        position: relative;
        margin: 0px;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;

        span {
            background: $secondary;
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 0px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: 10px;
            }

            &:nth-child(4) {
                top: 20px;
            }
        }
    }
}
