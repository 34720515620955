// production dropdown style start
.production-dropdown {
    --#{$prefix}production-btn-padding-x: 1.5rem;

    .dropdown-menu {
        // .production-dropdown variables start
        --#{$prefix}dropdown-zindex: #{$zindex-dropdown};
        --#{$prefix}dropdown-min-width: 12rem;
        --#{$prefix}dropdown-padding-x: 0.5rem;
        --#{$prefix}dropdown-padding-y: #{$dropdown-padding-y};
        --#{$prefix}dropdown-spacer: 0;
        @include rfs($h5-font-size, --#{$prefix}dropdown-font-size);
        --#{$prefix}dropdown-color: #{$dropdown-color};
        --#{$prefix}dropdown-bg: #{$dropdown-bg};
        --#{$prefix}dropdown-border-color: #{$dropdown-border-color};
        --#{$prefix}dropdown-border-radius: #{$dropdown-border-radius};
        --#{$prefix}dropdown-border-width: #{$dropdown-border-width};
        --#{$prefix}dropdown-inner-border-radius: #{$dropdown-inner-border-radius};
        --#{$prefix}dropdown-box-shadow: #{$dropdown-box-shadow};
        --#{$prefix}dropdown-link-color: #{$dropdown-link-color};
        --#{$prefix}dropdown-link-hover-color: #{$white};
        --#{$prefix}dropdown-link-hover-bg: #{$primary};
        --#{$prefix}dropdown-link-active-color: #{$white};
        --#{$prefix}dropdown-link-active-bg: #{$primary};
        --#{$prefix}dropdown-item-padding-x: 0.5rem;
        --#{$prefix}dropdown-item-padding-y: 0.5rem;
        // .production-dropdown variables end
        width: calc(100% - (var(--#{$prefix}production-btn-padding-x) * 2));
        left: 50%;
        transform: translateX(-50%);
        border: 0;

        li:last-child {
            .dropdown-item {
                border-bottom: none;
            }
        }

        .dropdown-item {
            font-weight: 700;
            border-bottom: solid 1px #{$secondary};
            white-space: normal;
            text-wrap: balance;
            line-height: 1.25;
        }
    }

    .production-btn {
        // .production-btn variables start
        --#{$prefix}btn-padding-x: var(--#{$prefix}production-btn-padding-x);
        --#{$prefix}btn-padding-y: 0.75rem;
        @include rfs($h4-font-size, --#{$prefix}btn-font-size);
        --#{$prefix}btn-font-weight: 800;
        --#{$prefix}btn-bg: #{$white};
        --#{$prefix}btn-border-color: #{$primary};
        --#{$prefix}btn-border-radius: 0.5rem;
        --#{$prefix}btn-hover-border-color: #{$primary};
        --#{$prefix}btn-box-shadow: inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
        --#{$prefix}btn-focus-box-shadow: 0 0 0 #{$btn-focus-width} rgba(var(--#{$prefix}btn-focus-shadow-rgb), .5);
        // .production-btn variables end
        display: flex;
        align-items: center;
        width: 100%;
        text-align: left; // Reset button style
        white-space: normal;
        text-wrap: balance;
        justify-content: space-between;
        line-height: 1.25;
        z-index: 1200;
        position: relative;

        &:focus {
            box-shadow: 0 1px 3px rgba($black, .5);
        }
    }

    .dropdown-toggle {
        &::after {
            transition: transform 0.25s ease-in-out;
        }

        &.show {
            &::after {
                transform: rotate(180deg);
            }
        }
    }
}

.production-collapse-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    @include clearfix();
}

.production-collapse {
    position: relative;
    display: unset !important;
    width: 100%;
    float: left;
    z-index: -1;
    margin-right: -100%;
    backface-visibility: hidden;
    opacity: 0;
    transition-property: opacity;
    @include transition(opacity 0.3s);

    &.show {
        z-index: 1;
        opacity: 1;
    }

    &.collapsing {
        height: 100%;
        overflow: auto;
        //@include transition($transition-collapse);
    }
}