//
// Custom Accordion
// --------------------------------------------------

//
// Base styles

.accordion-button {
    font-weight: 700;
}

/// Tab / Accordion
.tab-accordion {
    .nav-tabs {
        border-color: $primary;
    }

    .ta-header {
        text-align: center;
        margin-bottom: 0;
    }

    .ta-button, .nav-link {
        display: block;
        color: $primary;
        font-weight: 700;
        text-transform: uppercase;
    }

    .nav-link {
        border: none;
        border-right: solid 1px $primary;
        border-bottom: solid 1px transparent;
        border-radius: unset;

        &:last-of-type {
            border-right: none;
        }

        &.active, &:hover, &:focus {
            color: $orange;
            background: none;
            border-color: $light-green $primary;
        }
    }

    &.pub-resources {
        .nav-link {
            border: none;
            border-right: solid 1px $white;
            border-bottom: solid 1px transparent;
            border-radius: unset;
            color: $secondary;

            &:last-of-type {
                border-right: none;
            }

            &.active, &:hover, &:focus {
                color: $white;
                background: none;
                border-color: $light-green $primary;
            }
        }
    }

    .ta-button {
        border-top: solid 1px $primary;
        padding: .5rem;

        &:not(.collapsed) {
            color: $orange;
            border-bottom: solid 1px $primary;
        }
    }

    .tab-pane:first-of-type .ta-button {
        border-top: none;
    }

    .nav-tabs {
        display: none;
    }

    @include media-breakpoint-up(md) {
        .nav-tabs {
            display: flex;
        }

        .ta-header {
            display: none;
        }

        .collapse {
            display: block;
        }
    }

    @include media-breakpoint-down(sm) {
        .tab-content > .tab-pane {
            display: block !important;
            opacity: 1;
        }
    }
}